import '@moneylion/mlds-web/styles/fonts/fonts.css'
import '@moneylion/mlds-web/styles/fonts/icons.css'
import { ThemeProvider } from 'styled-components'
import { Dialog, GlobalStyle, useMLDSTheme } from '@moneylion/mlds-web'
import '@root/styles/index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect } from 'react'
import Script from 'next/script'
import { NextWebVitalsMetric } from 'next/app'
import { SplitFactory } from '@splitsoftware/splitio-react'
import { useRouter } from 'next/router'
import { ENV } from '@root/config'
import AppDialog from '@root/modules/AppDialog'
import { NotificationModal } from '@root/components/NotificationModal'
import { AppPropsWithLayout } from '@root/types'
import { SPLIT_FEATURE_FLAG, sdkConfig } from '@root/constants/featureFlagNames'
import {
  DDLogger,
  reportNextWebVitals,
  deviceIdUtils,
  observabilityUtils,
} from '@root/utils'
import { useSplitStore, useUIStore } from '@root/store'
import { DARK_MODE_PAGES_PATHS } from '@root/constants'
import { POD, POD_NAME, POD_ROUTES } from '@root/constants/team'
import { ErrorBoundary } from '@root/components/ErrorBoundary'
import Custom500 from './500'

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  reportNextWebVitals(metric)
}

export default function App({
  Component,
  pageProps,
}: AppPropsWithLayout): JSX.Element {
  // Register MLDS <Dialog /> showDialogsFn to show dialogs
  const registerShowDialogsFn = (showDialogsFn: any) => {
    window.showDialogs = showDialogsFn
  }

  const showDarkMode = useUIStore((state) => state.showDarkMode)

  const setShowDarkMode = useUIStore((state) => state.setShowDarkMode)

  const getAllFeatureFlagsStatus = useSplitStore(
    (state) => state.getAllFeatureFlagsStatus
  )

  const updateDarkModeStatus = () => {
    const shouldShowDarkMode = DARK_MODE_PAGES_PATHS.includes(router.pathname)
    setShowDarkMode(shouldShowDarkMode)
    return
  }

  const assignDataDogRumPodContext = (pod: POD_NAME) => {
    observabilityUtils.browserRum.addRumGlobalContext('team', pod)
  }

  const handleDatadogRumContextOwnership = () => {
    if (POD_ROUTES[POD.IDENTITY].includes(router.pathname)) {
      assignDataDogRumPodContext(POD.IDENTITY)
    } else if (POD_ROUTES[POD.ONBOARDING].includes(router.pathname)) {
      assignDataDogRumPodContext(POD.ONBOARDING)
    } else if (POD_ROUTES[POD.MARKETPLACE].includes(router.pathname)) {
      assignDataDogRumPodContext(POD.MARKETPLACE)
    } else if (POD_ROUTES[POD.GENERATIVE_AI].includes(router.pathname)) {
      assignDataDogRumPodContext(POD.GENERATIVE_AI)
    } else if (POD_ROUTES[POD.INSTACASH].includes(router.pathname)) {
      assignDataDogRumPodContext(POD.INSTACASH)
    } else if (POD_ROUTES[POD.DISCOVER].includes(router.pathname)) {
      assignDataDogRumPodContext(POD.DISCOVER)
    } else if (POD_ROUTES[POD.WEB_CORE].includes(router.pathname)) {
      assignDataDogRumPodContext(POD.WEB_CORE)
    } else {
      assignDataDogRumPodContext(POD.UNKNOWN)
    }
    return
  }
  useEffect(() => {
    observabilityUtils.browserRum.init()
    DDLogger.init()
    deviceIdUtils.initiateFingerprint()
  }, [])

  const getLayout = Component.getLayout ?? ((page) => page)

  const router = useRouter()

  const [taxFilingDisabled, searchDisabled, discoverDisabled] =
    getAllFeatureFlagsStatus(
      [
        SPLIT_FEATURE_FLAG.WEB.WEB_COLUMN_TAX,
        SPLIT_FEATURE_FLAG.SRCH.SEARCH_ROLLOUT,
        SPLIT_FEATURE_FLAG.DISCOVER.DISCOVER_FEED_ENABLED,
      ],
      'off'
    )

  useEffect(() => {
    // If the feature flag is disabled and a user attempts to navigate to a specific page route, they will be redirected to the offers page.
    const isDisabledPage =
      (taxFilingDisabled && router.pathname === '/tax-filing') ||
      (searchDisabled && router.pathname === '/search') ||
      (discoverDisabled && router.pathname === '/discover')

    if (isDisabledPage) {
      router.push('/products-offers')
    }
  }, [router, taxFilingDisabled, searchDisabled, discoverDisabled])

  useEffect(() => {
    updateDarkModeStatus()
    handleDatadogRumContextOwnership()
  }, [router.pathname])

  const theme = useMLDSTheme({ dark: showDarkMode })

  return (
    <SplitFactory config={sdkConfig}>
      <ThemeProvider theme={theme}>
        <Script id="segment-key">
          {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3";analytics.load("${ENV.SEGMENT_KEY}");}}();`}
        </Script>
        <Script id="appflyer">
          {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF", "pba,banners",{pba: {webAppId: "${ENV.APPS_FLYER_PBA_KEY}"}, banners: {key: "${ENV.APPS_FLYER_WEB_KEY}"}});`}
        </Script>
        <GlobalStyle />
        <AppDialog />
        <NotificationModal />
        <ErrorBoundary fallbackComponent={<Custom500 />}>
          {getLayout(<Component {...pageProps} />)}
        </ErrorBoundary>
        <Dialog.Manager registerShowDialogsFn={registerShowDialogsFn} />
      </ThemeProvider>
    </SplitFactory>
  )
}
