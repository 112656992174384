import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import jwt_decode from 'jwt-decode'
import { generateUniqueDeviceId } from '@root/helpers'
import { ENV } from '@root/config'
import { Token } from '@root/types'
import { useAuthStore } from '@root/store'
import { segmentApi } from '@root/api'
import { validateToken } from './validateToken'
import { DDLogger } from './logger'
import { httpsClient } from './httpsClient'

export interface RequestOptions {
  shouldCheckToken?: boolean
  expectErrorData?: boolean
  ignoreRequestTracking?: boolean
  mockDeviceId?: string
  useRecaptchaHeader?: boolean
  shouldAddRequestId?: boolean
  shouldBypassCaptcha?: boolean
  useAuthApi?: boolean
  useNewDashboardHeader?: boolean
  signal?: AbortSignal
  errorTitle: string
  columnTaxBackdoorSampleUser?: string
  customHeaders?: Record<string, string>
}

let refreshAccessTokenTimeout: NodeJS.Timeout

/**
 * @deprecated This function is no longer being maintained, use the refreshAccessToken() from refreshAccessToken.ts
 * This function is used to refresh the access token if user are logged in every 10 minutes
 * @param currentToken The present user accessToken
 * @returns NodeJS.Timeout
 */
const refreshAccessToken = (
  currentToken: string
): NodeJS.Timeout | undefined => {
  if (currentToken === '') {
    return
  }
  // JWT decode `currentToken` to get the expiration time
  const tokenPayload: Token = jwt_decode(currentToken)
  const remainingMilliSeconds = tokenPayload.exp * 1000 - new Date().getTime()
  const remainingMinutes = Math.floor(remainingMilliSeconds / 60000)

  let timeoutIn = 10 * 60000 // 10 minutes

  // Set timeoutIn based on remainingMinutes
  if (remainingMinutes > 0 && remainingMinutes < 11) {
    // Ensure a min of 1m (prevent loop) & max of 5m (refresh before token expiry)
    timeoutIn =
      Math.max(1, remainingMinutes > 5 ? 5 : remainingMinutes - 1) * 60000
  }

  clearTimeout(refreshAccessTokenTimeout)

  const getTokenIssuer = useAuthStore.getState().getTokenIssuer
  const refreshCommonAuthAccessToken =
    useAuthStore.getState().refreshAccessToken
  const removeAccessToken = useAuthStore.getState().removeAccessToken
  const setAccessToken = useAuthStore.getState().setAccessToken

  if (remainingMinutes > 0) {
    refreshAccessTokenTimeout = setTimeout(async () => {
      // get the token issuer
      const tokenIssuer = getTokenIssuer()

      // if it is MoneyLion, use auth api refresh endpoint
      // else use auth-js refresh fuction which calls auth web react
      if (tokenIssuer === 'MoneyLion') {
        const resp = await httpsClient('/refresh', {
          useAuthApi: true,
          shouldCheckToken: false,
          errorTitle: '[refresh access token]',
          method: 'get',
        })
        const { token } = resp.data

        if (!validateToken(token)) {
          removeAccessToken()
          window.location.reload()
          throw new Error('INVALID_TOKEN_OR_EXPIRED')
        }

        refreshAccessToken(token)
        setAccessToken(token)
        return resp
      } else {
        await refreshCommonAuthAccessToken()
        const newToken = useAuthStore.getState().getAccessToken()
        if (!validateToken(newToken)) {
          removeAccessToken()
          window.location.reload()
          throw new Error('INVALID_TOKEN_OR_EXPIRED')
        }
        refreshAccessToken(newToken)
      }
    }, timeoutIn) // Refresh token every 10 minutes
  }

  return refreshAccessTokenTimeout
}

/**
 * @deprecated This function is no longer being maintained, use httpsClient
 */
const https = async ({
  useRecaptchaHeader = false,
  shouldAddRequestId = false,
  shouldBypassCaptcha = false,
  useAuthApi = false,
  shouldCheckToken = true,
  useNewDashboardHeader = false,
  signal,
  expectErrorData = false,
  errorTitle,
  columnTaxBackdoorSampleUser = '',
  customHeaders = {},
}: RequestOptions): Promise<AxiosInstance> => {
  const headers: Partial<AxiosRequestHeaders> = customHeaders
  const anonId = segmentApi.getAnonId()

  headers['Content-Type'] = 'application/json'
  headers.Accept = 'application/json'
  headers['x-app-source'] = 'webApp'

  headers['x-device-id'] = await generateUniqueDeviceId()
  headers['x-anonymous-id'] = anonId

  const token = useAuthStore.getState().getAccessToken()
  const removeAccessToken = useAuthStore.getState().removeAccessToken
  const setAccessToken = useAuthStore.getState().setAccessToken
  const tokenIssuer = useAuthStore.getState().getTokenIssuer()

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (tokenIssuer !== 'MoneyLion') {
    headers['x-use-common-auth'] = '1'
  }

  if (useRecaptchaHeader) {
    headers['x-recaptcha'] = '1'
  }

  if (shouldAddRequestId) {
    headers['x-request-id'] = uuidv4()
  }

  if (shouldBypassCaptcha && ENV.APP_ENV !== 'production') {
    headers['x-cf-captcha-bypass'] = '748d717e-356b-4789-96ba-7c7fd3862bd9'
  }

  if (useNewDashboardHeader) {
    headers['x-new-dashboard'] = '1'
  }

  if (ENV.APP_ENV !== 'production' && columnTaxBackdoorSampleUser.length > 0) {
    headers['x-use-columntax-backdoor-user'] = columnTaxBackdoorSampleUser
  }

  const axiosInstance = axios.create({
    baseURL: useAuthApi ? ENV.AUTH_API_URL : ENV.API_URL,
    headers,
    timeout: 60000,
    timeoutErrorMessage: 'TIMEOUT',
    signal,
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.status === 408) {
        throw new Error('TIMEOUT')
      }

      if (shouldCheckToken && !validateToken(token || '')) {
        removeAccessToken()
        window.location.reload() // if token expired refresh the page to log out user.
        throw new Error('INVALID_TOKEN_OR_EXPIRED')
      }

      const xAuthToken = response.headers['x-auth']

      if (shouldCheckToken) {
        if (xAuthToken && xAuthToken !== token) {
          setAccessToken(xAuthToken)
          refreshAccessToken(xAuthToken)
        } else if (token && refreshAccessTokenTimeout === undefined) {
          refreshAccessToken(token)
        }
      }
      return response
    },
    (error) => {
      DDLogger.error(errorTitle, error)
      // This error corresponds to unauthorized access on the express backend
      if (error.response?.data?.code === 'G001') {
        DDLogger.info(errorTitle, 'Unauthorized access')
        removeAccessToken()
        window.location.reload() // if token invalid refresh the page to log out user.
      }

      if (error.response?.status === 408) {
        DDLogger.info(errorTitle, 'Request timeout')
        throw new Error('TIMEOUT')
      }

      if (expectErrorData) {
        DDLogger.info(errorTitle, 'Expect error data')
        throw error.response?.data || error.response
      }

      DDLogger.error(
        errorTitle,
        `Reject error response: ${
          JSON.stringify(error.response?.data) || JSON.stringify(error)
        }`
      )
      return Promise.reject(error.response?.data || error)
    }
  )

  return axiosInstance
}

export { https, refreshAccessToken as deprecatedRefreshAccessToken }
