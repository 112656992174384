import { create } from 'zustand'
import { referralApi } from '@root/api'
import IReferrals, { IReferralSummary } from '@root/types/Referrals'
import { useAuthStore } from './authStore'

interface ReferralInitialState {
  referrals: IReferrals | null
  referralSummary: IReferralSummary | null
  loaded: boolean
}
interface ReferralsState extends ReferralInitialState {
  getReferrals: () => void
  getReferralSummary: () => void
  reset: () => void
}

const initialState: ReferralInitialState = {
  referrals: null,
  referralSummary: null,
  loaded: false,
}

const useReferralsStore = create<ReferralsState>()((set) => ({
  ...initialState,
  getReferrals: async () => {
    const { user } = useAuthStore.getState()
    const referrals = await referralApi.fetchReferrals(user)
    set({ referrals })
  },
  getReferralSummary: async () => {
    const referralSummary = await referralApi.fetchReferralSummary()
    set({ referralSummary })
    set({ loaded: true })
  },
  reset: () => {
    set(initialState)
  },
}))

export { useReferralsStore }
