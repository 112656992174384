import jwt_decode from 'jwt-decode'
import { useAuthStore } from '@root/store'
import { Token } from '@root/types'
import { httpsClient } from './httpsClient'
import { validateToken } from './validateToken'

let refreshAccessTokenTimeout: NodeJS.Timeout

const refreshAccessToken = (
  currentToken: string
): NodeJS.Timeout | undefined => {
  if (!currentToken) return
  // JWT decode `currentToken` to get the expiration time
  const tokenPayload: Token = jwt_decode(currentToken)
  const remainingMilliSeconds = tokenPayload.exp * 1000 - new Date().getTime()
  const remainingMinutes = Math.floor(remainingMilliSeconds / 60000)

  let timeoutIn = 10 * 60000 // 10 minutes

  // Set timeoutIn based on remainingMinutes
  if (remainingMinutes > 0 && remainingMinutes < 11) {
    // Ensure a min of 1m (prevent loop) & max of 5m (refresh before token expiry)
    timeoutIn =
      Math.max(1, remainingMinutes > 5 ? 5 : remainingMinutes - 1) * 60000
  }

  clearTimeout(refreshAccessTokenTimeout)

  const getTokenIssuer = useAuthStore.getState().getTokenIssuer
  const refreshCommonAuthAccessToken =
    useAuthStore.getState().refreshAccessToken
  const removeAccessToken = useAuthStore.getState().removeAccessToken
  const setAccessToken = useAuthStore.getState().setAccessToken

  if (remainingMinutes > 0) {
    refreshAccessTokenTimeout = setTimeout(async () => {
      // get the token issuer
      const tokenIssuer = getTokenIssuer()

      // if it is MoneyLion, use auth api refresh endpoint
      // else use auth-js refresh fuction which calls auth web react
      if (tokenIssuer === 'MoneyLion') {
        const resp = await httpsClient('/refresh', {
          useAuthApi: true,
          shouldCheckToken: false,
          errorTitle: '[refresh access token]',
          method: 'get',
        })
        const { token } = resp.data

        if (!validateToken(token)) {
          removeAccessToken()
          window.location.reload()
          throw new Error('INVALID_TOKEN_OR_EXPIRED')
        }

        refreshAccessToken(token)
        setAccessToken(token)
        return resp
      } else {
        await refreshCommonAuthAccessToken()
        const newToken = useAuthStore.getState().getAccessToken()
        if (!validateToken(newToken)) {
          removeAccessToken()
          window.location.reload()
          throw new Error('INVALID_TOKEN_OR_EXPIRED')
        }
        refreshAccessToken(newToken)
      }
    }, timeoutIn) // Refresh token every 10 minutes
  }

  return refreshAccessTokenTimeout
}

export { refreshAccessToken }
