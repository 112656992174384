export enum MarketplaceAnalyticsCardsLocations {
  CONTENTFUL_OFFERS = 'marketplace',
  EVEN_PERSONALISED_OFFERS = 'even-offers',
  EVEN_CREDIT_BUILDER_OFFER_WALL = 'credit-builder-offer-wall',
  EVEN_CREDIT_BUILDER_OFFER_CAROUSEL = 'credit-builder-offers-horizontal',
  EVEN_EWA_OFFERS_WALL = 'ewa-offer-wall',
  EVEN_EWA_OFFERS_CAROUSEL = 'ewa-offers-horizontal',
  CC_OFFERS_CAROUSEL = 'credit-cards-horizontal',
  CC_OFFERS_WALL = 'credit-card-offers-wall',
  SAVING_CARDS = 'savings-offers-horizontal',
  SAVING_CARDS_WALL = 'savings-offers-wall',
}

export enum MarketplaceAnalyticsCardsElements {
  CONTENTFUL_OFFERS = 'offer-tile',
  EVEN_PERSONALISED_OFFERS = 'offer-tile',
  EVEN_CREDIT_BUILDER_OFFER_WALL = 'credit-builder-tile',
  EVEN_CREDIT_BUILDER_OFFER_CAROUSEL = 'even-offer-tile',
  EVEN_EWA_OFFERS_WALL = 'ewa-card-tile',
  EVEN_EWA_OFFERS_CAROUSEL = 'ewa-card-tile',
  CC_OFFERS_CAROUSEL = 'credit-card-tile',
  CC_OFFERS_WALL = 'credit-card-tile',
  SAVING_CARDS = 'savings-offer-tile',
}

export enum MarketplaceAnalyticsCardsProducts {
  CREDIT_CARD = 'credit-card',
}

export enum MarketplaceAnalyticsCardsTrafficSources {
  ACTIVE_LOAN_OFFERS = 'marketplace',
}

export enum MarketplaceAnalyticsSectionsLocations {
  EVEN_EWA_CAROUSEL = 'ewa-offers-horizontal',
  EVEN_CREDIT_BUILDER = 'credit-builder-offers-horizontal',
  CC_CAROUSEL = 'credit-cards-horizontal',
  SAVINGS_CALCULATOR = 'loan-marketplace',
}

export enum MarketplaceAnalyticsSectionsElements {
  EVEN_EWA_CAROUSEL = 'ewa-card-tile',
  EVEN_CREDIT_BUILDER = 'offer-tile',
  CC_CAROUSEL = 'credit-card-tile',
  SAVINGS_CALCULATOR = 'savings-tile',
}

export enum MarketplaceAnalyticsWidgetsLocations {
  FIONA = 'marketplace',
  LIAT = 'marketplace',
  LAC = 'marketplace',
}

export enum MarketplaceAnalyticsWidgetsElements {
  FIONA = 'savings-fiona-widget',
  LIAT = 'offer-tile',
  LAC = 'offer-tile',
}

export const DEFAULT_TRAFFIC_SOURCE = 'marketplace'
export const DEFAULT_WEBDASH_TRAFFIC = 'webdash'
export const SEARCH_TRAFFIC_SOURCE = 'search_feed'
export const DEFAULT_MEDIUM = 'webdash_liat'

export enum MarketplaceCreditCardsPathsNames {
  PURPOSE_CHOICE = 'purpose-choice',
  EMAIL = 'email',
  PERSONAL_INFORMATION = 'personal-details',
  ADDRESS = 'address',
  EMPLOYMENT = 'employment',
  SSN = 'ssn',
  CONFIRMATION = 'confirmation',
  OFFERS_RESULT = 'offers-result',
  CREDIT_SCORE = 'credit-score',
}
