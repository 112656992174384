import { httpsClient } from '@root/utils'
import { IContentGenResponseData } from '@root/types'
import { SOCIAL_MEDIA_API_PATHS } from '../helpers/todayConstants'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SocialMediaRequest {
  export type SaveReactionParams = {
    contentId: string
    reaction: string
    contentSource: string
  }
}

const saveReaction = async ({
  contentId,
  ...body
}: SocialMediaRequest.SaveReactionParams): Promise<IContentGenResponseData> => {
  const response = await httpsClient(
    SOCIAL_MEDIA_API_PATHS.SAVE_REACTION(contentId),
    {
      body,
      method: 'POST',
      errorTitle: '[saveReaction]',
    }
  )
  return response.data
}

const socialMediaApi = {
  saveReaction,
}

export default socialMediaApi
