export enum DISCOVER_EVENT_NAMES {
  DISCOVER_CTA = 'discover_cta',
  DISCOVER_ERROR = 'discover_error',
  DISCOVER_POST_SCREEN_VIEW = 'discover_post_screen_view',
  DISCOVER_CONTENT_STATUS = 'Content Status',
}

export const DISCOVER_SCROLL_EVENT = {
  NAME: 'discover_scroll',
  START: 'start',
  STOP: 'stop',
}

export const TODAY_HEART_ANALYTICS = {
  EVENT: 'today_heart_interact',
  HEARTED: 'like',
  UNHEARTED: 'unlike',
}

export enum VIDEO_EVENTS {
  VIDEO_PLAYBACK_INTERACT = 'video_playback_interact',
  VIDEO_PLAYBACK_START = 'video_playback_start',
  VIDEO_PLAYBACK_COMPLETE = 'video_playback_complete',
  VIDEO_INTERACT_PLAYBACK_TIMESPENT = 'video_interact_playback_timespent',
  VIDEO_PLAYBACK_PAUSE = 'video_playback_pause',
  VIDEO_PLAYBACK_RESUME = 'video_playback_resume',
  VIDEO_PLAYBACK_FORWARD = 'video_playback_forward',
  VIDEO_PLAYBACK_REWIND = 'video_playback_rewind',
  VIDEO_PLAYBACK_EXIT = 'video_playback_exit',
}
