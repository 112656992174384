import { useEffect, FunctionComponent } from 'react'
import Image from 'next/image'
import { Body, Dialog, Title } from '@moneylion/mlds-web'
import { useTheme } from 'styled-components'
import { useUIStore } from '@root/store'
import { useCloseModalEventListener } from '@root/helpers'
import labels from './labels'
import { LegalAgreementDocument } from '@onboarding/components'

const AppDialog: FunctionComponent = () => {
  const theme = useTheme()
  const showAppDialog = useUIStore((state) => state.showAppDialog)
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)

  const options = useUIStore((state) => state.appDialogOptions)
  useCloseModalEventListener(() => setShowAppDialog(false))

  useEffect(() => {
    if (showAppDialog) {
      showDialog()
      setShowAppDialog(false, options)
    }
  }, [showAppDialog])

  const showDialog = (): void => {
    const titleSize = options?.titleSize || 18
    const titleAlign = options?.titleAlign || 'text-center'
    const textAlign = options?.textAlign || 'text-center'
    const dialogWidth = options?.dialogSize === 'Large' ? 448 : undefined

    window.showDialogs(
      <Dialog
        isDismissable={!options || options?.isDismissable || false}
        width={dialogWidth}
        style={{
          backgroundColor: theme.colors['background-secondary'],
        }}
      >
        {({ closeDialog }) =>
          options ? (
            <>
              {options?.image?.src && (
                <Image
                  src={options.image.src}
                  alt="banner"
                  width={options?.image?.width}
                  height={options?.image?.height}
                />
              )}
              <Title className={titleAlign} style={{ fontSize: titleSize }}>
                {options.title}
              </Title>
              {options.subtitle && (
                <Body
                  className={textAlign}
                  style={{ width: '100%', textTransform: 'capitalize' }}
                >
                  {options.subtitle}
                </Body>
              )}
              {options.text &&
                options.text.length !== 0 &&
                options.text.map((item, index) => (
                  <Body
                    key={`dialog-text-${index}`}
                    className={textAlign}
                    color="secondary"
                    style={{ width: '100%' }}
                  >
                    {item}
                  </Body>
                ))}
              <Dialog.Button.Container direction={options.buttonDirection}>
                {options.buttons.map((button) => (
                  <Dialog.Button
                    data-testid={button.dataTestId}
                    style={{ flex: '1 1 auto' }}
                    key={button.label}
                    onClick={() => {
                      if (button.onClick) {
                        button.onClick(closeDialog)
                      } else {
                        closeDialog()
                      }
                    }}
                    color={button.color || 'primary'}
                  >
                    {button.label}
                  </Dialog.Button>
                ))}
              </Dialog.Button.Container>
              {options.legalAgreement && (
                <LegalAgreementDocument
                  id={options.legalAgreement.docId}
                  name={options.legalAgreement.docName}
                  onClickAccept={options.legalAgreement.onClickAccept}
                  size="small"
                  type="label"
                />
              )}
            </>
          ) : (
            // Will show generic error modal by default
            <>
              <Dialog.Title text={labels.title} />
              <Dialog.Text text={labels.text} />
              <Dialog.Button.Container direction="row">
                <Dialog.Button onClick={closeDialog} fullWidth>
                  {labels.gotItButton}
                </Dialog.Button>
              </Dialog.Button.Container>
            </>
          )
        }
      </Dialog>
    )
  }

  return null
}

export default AppDialog
