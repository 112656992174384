import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { subscriptionApi } from '@root/api'
import { useBackdoorStore, useBankStore } from '@root/store'

interface DisbursementInfo {
  disbursementAmt: number
  escrowDisbursementAmt: number
  apr: number
  totalAmount: number
}
interface CBPInitialState {
  disbursementInfo: DisbursementInfo
}

export interface CBPStore extends CBPInitialState {
  checkCBPlusBvUnderwriting: (signal?: AbortSignal) => Promise<boolean>
  reset: () => void
}

const initialState: CBPInitialState = {
  disbursementInfo: {
    apr: 0,
    disbursementAmt: 0,
    escrowDisbursementAmt: 0,
    totalAmount: 0,
  },
}
const useCBPStore = create<CBPStore>()(
  persist(
    (set) => ({
      ...initialState,
      checkCBPlusBvUnderwriting: async (signal) => {
        const { isCbplusBvLinkingBackdoorEnabled, cbplusBvLinkingTestValues } =
          useBackdoorStore.getState()

        const { setLinkedBank } = useBankStore.getState()

        const { data } = await subscriptionApi.postCBPlusBvUnderwriting(
          isCbplusBvLinkingBackdoorEnabled
            ? cbplusBvLinkingTestValues
            : undefined,
          signal
        )

        const {
          status,
          disbursementAmt,
          escrowDisbursementAmt,
          apr,
          bankName,
          last4DigitAccountNumber,
        } = data

        if (status === 'CBP_SUCCESS') {
          set({
            disbursementInfo: {
              disbursementAmt,
              escrowDisbursementAmt,
              apr,
              totalAmount: disbursementAmt + escrowDisbursementAmt,
            },
          })
          setLinkedBank({
            bankName,
            last4DigitAccountNumber,
          })
        }

        return status === 'CBP_SUCCESS'
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        disbursementInfo: state.disbursementInfo,
      }),
      name: 'cbPlusStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useCBPStore }
