import { httpsClient } from '@root/utils/httpsClient'
import { IContentCardDataResponse, IContentGenResponseData } from '@root/types'

type Params = {
  [key: string]: string | number | boolean
}

const fetchTodayTabContent = async (
  params: Params = {}
): Promise<IContentGenResponseData> => {
  const response = await httpsClient(`/content/todayTabContentV2`, {
    params,
    method: 'GET',
    errorTitle: '[fetchTodayTabContent]',
  })
  return response.data
}

const fetchTodayTabContentWithFallback = async (
  params: Params = {}
): Promise<IContentGenResponseData> => {
  const response = await httpsClient(`/content/todayTabContentV2Proxy`, {
    params,
    method: 'GET',
    errorTitle: '[fetchTodayTabContentWithFallback]',
  })
  return response.data
}

const getTodayTabContent = async ({
  contentId,
  ...params
}: Params & { contentId: string }): Promise<IContentCardDataResponse> => {
  const response = await httpsClient(
    `/content/todayTabContentV2/${contentId}`,
    {
      params,
      method: 'GET',
      errorTitle: '[getTodayTabContent]',
    }
  )
  return response.data
}

const contentGenApi = {
  fetchTodayTabContentWithFallback,
  fetchTodayTabContent,
  getTodayTabContent,
}

export default contentGenApi
