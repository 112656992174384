import ICheckingAccount from '@root/types/CheckingAccount'
import { https } from '@root/utils'

interface IFetchCheckingAccount {
  data: ICheckingAccount
}

/**
 * @deprecated: Migrate to '@rm/api'
 */
const fetchCheckingAccount = async (): Promise<IFetchCheckingAccount> => {
  const response = await (
    await https({
      errorTitle: '[fetchCheckingAccount]',
    })
  ).get('/checking-account/info')
  return response.data
}

const roarMoneyApi = {
  fetchCheckingAccount,
}

export default roarMoneyApi
