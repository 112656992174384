import { create } from 'zustand'
import {
  SplitFeatureFlagTreatment,
  SplitFeatureFlagValueType,
  SplitFetchStatus,
} from '@root/types/Featureflag'
import Split from '@root/utils/splitClient'

interface SplitState {
  featureFlags: SplitIO.TreatmentsWithConfig
  isReady: boolean
  isGettingReady: boolean
  splitFetchStatus: SplitFetchStatus
}

type TreatmentFFOption = Record<
  number,
  Record<string, boolean | number | string>
>

interface SplitActions {
  setFeatureFlags: (ffConfigs: SplitIO.TreatmentsWithConfig) => void
  getSplitFetchStatus: () => SplitFetchStatus
  getSplitTreatmentIfReady: (
    split: SplitFeatureFlagValueType
  ) => SplitIO.TreatmentWithConfig | undefined
  getFeatureFlagConfigs: <ConfigType extends object>(
    featureFlagName: SplitFeatureFlagValueType
  ) => ConfigType | null
  setSplitFetchStatus: (status: SplitFetchStatus) => void
  getAllFeatureFlagsStatus: (
    names: SplitFeatureFlagValueType[],
    status: SplitFeatureFlagTreatment,
    treatment?: TreatmentFFOption // <indexNumber, { view: boolean }>
  ) => boolean[]
  getFeatureFlagWithCustomAttributes: (
    featureFlag: SplitFeatureFlagValueType,
    customAttributes: SplitIO.Attributes
  ) => SplitIO.TreatmentWithConfig | null
}

const useSplitStore = create<SplitState & SplitActions>((set, getState) => ({
  featureFlags: {},
  isReady: false,
  isGettingReady: false,
  splitFetchStatus: 'Fetching',
  setSplitFetchStatus: (splitFetchStatus: SplitFetchStatus) =>
    set({ splitFetchStatus: splitFetchStatus }),
  setFeatureFlags: (featureFlags: SplitIO.TreatmentsWithConfig) =>
    set({ featureFlags: featureFlags }),
  setIsReady: (isReady: boolean) => set({ isReady }),
  getSplitFetchStatus: () => {
    const split = getState()
    if (split.isReady && !split.isGettingReady) {
      set({ splitFetchStatus: 'Success' })
      return 'Success'
    } else if (split.isGettingReady && !split.isReady) {
      set({ splitFetchStatus: 'Fetching' })
      return 'Fetching'
    } else {
      set({ splitFetchStatus: 'Error' })
      return 'Error'
    }
  },
  getFeatureFlagConfigs: <ConfigType extends object>(
    featureFlagName: SplitFeatureFlagValueType
  ) => {
    const featureFlag = getState().featureFlags[featureFlagName]
    if (featureFlag && featureFlag.config) {
      const parsedConfigs = JSON.parse(featureFlag.config)
      return parsedConfigs as ConfigType
    }
    return null
  },
  /**
   * @usage Get treatment for a ready split
   *
   * Usage example:
   *
   * ```jsx
   *  const isEnabled = getSplitTreatmentIfReady('xxx')?.treatment === 'on'
   * ```
   *
   **/
  getSplitTreatmentIfReady: (split: SplitFeatureFlagValueType) => {
    const featureFlags = getState().featureFlags
    return getState().isReady ? featureFlags?.[split] : undefined
  },
  getAllFeatureFlagsStatus: (
    names: SplitFeatureFlagValueType[] = [],
    status: SplitFeatureFlagTreatment,
    treatment?: TreatmentFFOption // <indexNumber, { view: boolean }>
  ) => {
    const featureFlags = getState().featureFlags

    return names.map((name, idx) => {
      const ffTreatment = treatment?.[idx]
      const configTreatment = featureFlags[name]?.config
      if (ffTreatment && typeof configTreatment === 'string') {
        const keys = Object.keys(ffTreatment)
        const parsedConfigTreatment = JSON.parse(configTreatment)
        const every = keys.every(
          (k) => ffTreatment[k] === parsedConfigTreatment?.[k]
        )
        return every
      }
      return featureFlags[name]?.treatment === status
    })
  },

  /**
   * Returns treatment config for a feature flag based on any passed custom attributes.
   * If the feature flag is not found, it returns undefined.
   * @param {SplitFeatureFlagValueType} featureFlag - Feature flag name
   * @param {SplitIO.Attributes} customAttributes  - Custom attributes to be passed to the split client
   * @returns {SplitIO.TreatmentWithConfig | undefined} - Returns the treatment value for the feature flag
   */
  getFeatureFlagWithCustomAttributes: (
    featureFlag: SplitFeatureFlagValueType,
    customAttributes: SplitIO.Attributes
  ) => {
    const featureFlagValue = Split.getSplitFeatureFlagWithAttributes(
      featureFlag,
      customAttributes
    )

    if (featureFlagValue?.treatment) {
      set({
        featureFlags: {
          ...getState().featureFlags,
          [featureFlag]: featureFlagValue,
        },
      })
      return featureFlagValue
    }

    return null
  },
}))
export { useSplitStore }
