import { load } from 'recaptcha-v3'
import { ENV } from '@root/config'
import { DDLogger } from '@root/utils'

const getRecaptchaToken = async (action: string): Promise<string> => {
  try {
    const recaptcha = await load(ENV.RECAPTCHA_KEY, { useEnterprise: true })
    const token = await recaptcha.execute(action)

    return await Promise.resolve(token)
  } catch (err) {
    DDLogger.error('getRecaptchaToken', err)
    return Promise.reject(err)
  }
}

const recaptchaApi = {
  getRecaptchaToken,
}

export default recaptchaApi
