export const TWITTER_BLUE = '#1DA1F2'
export const TWITTER_BASE_URL = 'https://x.com'
export const MAX_BIT_RATE_ON_WIFI = 1000000

export enum TWEET_TEXT_TYPE {
  TEXT = 'text',
  HASHTAG = 'hashtag',
  USER_MENTION = 'user_mention',
  LINK = 'link',
}

export enum OTHERS_VIEW_ON_TWITTER_TYPE {
  TWEET = 'tweet',
  TWEET_AUTHOR = 'author',
}

export type VIEW_ON_TWITTER_TYPE = TWEET_TEXT_TYPE | OTHERS_VIEW_ON_TWITTER_TYPE

export enum TWEET_MEDIA_TYPE {
  PHOTO = 'photo',
  VIDEO = 'video',
  GIF = 'GIF',
  ANIMATED_GIF = 'animated_gif',
}
