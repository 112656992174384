import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { sessionStorageApi } from '@root/api'
import { CommonAuthSignUpFlow, CommonAuthSignInFlow } from './authStore'

interface OnboardingInitialState {
  isIntentFlow?: boolean
  isSignUpSuccess: boolean
  isRoarmoneyDirectDepositFlow: boolean
  commonAuthSignUpFlow?: CommonAuthSignUpFlow
  commonAuthSignInFlow?: CommonAuthSignInFlow
  ssnAttemptCount: number
}

export interface OnboardingState extends OnboardingInitialState {
  setIsIntentFlow: (isIntentFlow: boolean) => void
  setIsSignUpSuccess: (isSignUpSuccess: boolean) => void
  setIsRoarmoneyDirectDepositFlow: (
    isRoarmoneyDirectDepositFlow: boolean
  ) => void
  isTaxFilingFlow: () => boolean
  setCommonAuthSignUpFlow: (commonAuthSignUpFlow?: CommonAuthSignUpFlow) => void
  setCommonAuthSignInFlow: (commonAuthSignInFlow?: CommonAuthSignInFlow) => void
  increaseSsnAttemptCount: () => void
  reset: () => void
}

const initialState: OnboardingInitialState = {
  isIntentFlow: undefined,
  isSignUpSuccess: false,
  isRoarmoneyDirectDepositFlow: false,
  ssnAttemptCount: 0,
}

const useOnboardingStore = create<OnboardingState>()(
  persist(
    (set) => ({
      ...initialState,
      setIsIntentFlow: (isIntentFlow) => {
        set({ isIntentFlow })
      },
      setIsSignUpSuccess: (isSignUpSuccess) => {
        set({ isSignUpSuccess: isSignUpSuccess })
      },
      setIsRoarmoneyDirectDepositFlow: (isRoarmoneyDirectDepositFlow) => {
        set({ isRoarmoneyDirectDepositFlow })
      },
      isTaxFilingFlow: () => {
        return (
          sessionStorageApi.get('taxfiling') === 'true' ||
          sessionStorageApi.get('taxfilingInitiatedFromDashboard') === 'true'
        )
      },
      setCommonAuthSignUpFlow: (commonAuthSignUpFlow) => {
        set({ commonAuthSignUpFlow })
      },
      setCommonAuthSignInFlow: (commonAuthSignInFlow) => {
        set({ commonAuthSignInFlow })
      },
      increaseSsnAttemptCount: () => {
        set((state) => ({
          ssnAttemptCount: state.ssnAttemptCount + 1,
        }))
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        isIntentFlow: state.isIntentFlow,
        isSignUpSuccess: state.isSignUpSuccess,
        isRoarmoneyDirectDepositFlow: state.isRoarmoneyDirectDepositFlow,
        commonAuthSignUpFlow: state.commonAuthSignUpFlow,
        commonAuthSignInFlow: state.commonAuthSignInFlow,
        ssnAttemptCount: state.ssnAttemptCount,
      }),
      name: 'onboardingStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useOnboardingStore }
