import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface IdleState {
  idle: boolean
  setIdle: (idle: boolean) => void
  dialogOpen: boolean
  setDialogOpen: (dialogOpen: boolean) => void
  reset: () => void
}

const defaultState: IdleState = {
  idle: false,
  dialogOpen: false,
  setDialogOpen: () => void 0,
  setIdle: () => void 0,
  reset: () => void 0,
}

const useIdleStore = create<IdleState>()(
  persist(
    (set) => ({
      idle: false,
      dialogOpen: false,
      setDialogOpen: (dialogOpen: boolean) => {
        set({ dialogOpen })
      },
      setIdle: (idle: boolean) => {
        set({ idle })
      },
      reset: () => {
        set(() => {
          return {
            ...defaultState,
          }
        })
      },
    }),
    {
      name: 'idleStorage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useIdleStore }
