import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { useAuthStore, useOnboardingStore } from '@root/store'
import { formats } from '@root/utils'
import { windowAnalyticsObjectExists } from '@root/helpers'

interface UserTraits {
  email?: string
  ml_user_id?: string
  name?: string
  phone?: string
  username?: string
  firstName?: string
  lastName?: string
  createdAt?: string
  displayName?: string
  tid?: string
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
  mpl_guest_creation_time?: string
}

/**
 * Send a page event to Segment whenever user loads a page.
 *
 * By default, we sent the following custom properties:
 * Next.js for tracking performances and correlate with product metrics:
 * - Next.js-hydration: The time it took for the first page to render
 * - Next.js-render: The time it took for the page to render
 * - Next.js-route-change-to-render: The time it took for the route change to render
 * Marketing tracking:
 * - fbclid: Facebook click ID
 * - gclid: Google click ID
 *
 * See https://segment.com/docs/connections/spec/page/
 */
const page = (): void => {
  windowAnalyticsObjectExists() &&
    window.analytics.page(undefined, undefined, {
      'Next.js-hydration': window.nextIsFirstPageMetricSent
        ? undefined
        : window.nextHydration,
      'Next.js-render': window.nextRender,
      'Next.js-route-change-to-render': window.nextRouteChangeToRender,
      fbclid: new URLSearchParams(window.location.search).get('fbclid'),
      gclid: new URLSearchParams(window.location.search).get('gclid'),
    })
}

const identify = (
  userId: string | undefined,
  traitsOrCallback?: UserTraits | (() => void),
  options = {},
  callback = () => void 0
): void => {
  if (typeof traitsOrCallback === 'function') {
    windowAnalyticsObjectExists() &&
      window.analytics.identify(userId, traitsOrCallback)
  } else {
    windowAnalyticsObjectExists() &&
      window.analytics.identify(userId, traitsOrCallback, options, callback)
  }
}

const trackWithOSPlatform = (
  event: string,
  properties: { [key: string]: any } = {},
  options: { [key: string]: any } = {},
  callback = () => void 0
): void => {
  const { user } = useAuthStore.getState()
  const { isIntentFlow } = useOnboardingStore.getState()

  const getFlow = (): string | undefined => {
    if (
      typeof window === 'undefined' ||
      !window.location.pathname.includes('onboarding')
    ) {
      return
    }

    return isIntentFlow ? 'intent' : 'default'
  }

  const defaultProperties: { [key: string]: unknown } = {
    os: formats.OS(),
    platform: 'Web',
    source: 'webApp', // To cater for the legacy segment events
    ml_user_id: user?.id,
    flow: getFlow(),
  }

  windowAnalyticsObjectExists() &&
    window.analytics.track(
      event,
      {
        ...defaultProperties,
        ...properties,
      },
      options,
      callback
    )
}

/**
 * trackWithOSPlatformMPL - track with OS and platform for MPL
 * This is similar to trackWithOSPlatform without the flow property
 *
 * @TODO: possibly merge this with trackWithOSPlatform
 * @see trackWithOSPlatform
 * @param event
 * @param properties
 * @param options
 * @param callback
 */
const trackWithOSPlatformMPL = (
  event: string,
  properties: { [key: string]: any } = {},
  options: { [key: string]: any } = {},
  callback = () => void 0
): void => {
  const { user } = useAuthStore.getState()

  const defaultProperties: { [key: string]: unknown } = {
    os: formats.OS(),
    platform: 'Web',
    source: 'webApp', // To cater for the legacy segment events
    ml_user_id: user?.id,
  }

  windowAnalyticsObjectExists() &&
    window.analytics.track(
      event,
      {
        ...defaultProperties,
        ...properties,
      },
      options,
      callback
    )
}

const trackOnelink = (
  properties: { [key: string]: any },
  options: { [key: string]: any } = {},
  callback = () => void 0
): void => {
  const { user } = useAuthStore.getState()
  windowAnalyticsObjectExists() &&
    window.analytics.track(
      'acc_appsflyer_onelink_direct',
      {
        ...properties,
        ml_user_id: user?.id,
        source: 'webApp',
        entry_point: window.location.pathname,
      },
      options,
      callback
    )
}

const getAnonId = (): string => {
  const anonymousId = Cookies.get('ajs_anonymous_id')

  if (anonymousId) {
    return anonymousId
  }

  const id = uuidv4()
  Cookies.set('ajs_anonymous_id', id, { expires: 365 })
  return id
}

const segmentApi = {
  page,
  identify,
  trackWithOSPlatform,
  trackOnelink,
  trackWithOSPlatformMPL,
  getAnonId,
}

export default segmentApi
