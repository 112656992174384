import { Dialog } from '@moneylion/mlds-web'
import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import styled, { useTheme } from 'styled-components'
import { useViewport } from '@root/hooks'
import { useUIStore } from '@root/store'

const StyledIframe = styled.iframe`
  width: 100%;
  height: 600px;
  border: 1px solid #f3f5f9;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.mobile}) {
    height: 350px;
  }
`

/**
 * ! DEPRECATING: Migrating to useLegalAgreementDialogV2()
 * @deprecated
 */
const LegalAgreementDialog: React.FunctionComponent = () => {
  const PDFViewer = dynamic<{ file?: string }>(() =>
    import('@root/components/PDFViewer/PDFViewer').then((mod) => mod.PDFViewer)
  )

  const theme = useTheme()

  const { viewport } = useViewport()

  const showLegalAgreementDialog = useUIStore(
    (state) => state.showLegalAgreementDialog
  )
  const setShowLegalAgreementDialog = useUIStore(
    (state) => state.setShowLegalAgreementDialog
  )

  const options = useUIStore((state) => state.legalAgreementDialogOptions)

  const isBlobUrl = options?.src?.startsWith('blob:')

  useEffect(() => {
    if (showLegalAgreementDialog) {
      showDialog()
      setShowLegalAgreementDialog(false, options)
    }
  }, [showLegalAgreementDialog])

  const showDialog = (): void => {
    return window.showDialogs(
      <Dialog
        width={600}
        style={{
          backgroundColor: theme.colors['background-secondary'],
        }}
      >
        {({ closeDialog }) => (
          <>
            <Dialog.Title text={options?.title || ''} />
            {viewport !== 'desktop' && isBlobUrl ? (
              <PDFViewer file={options?.src} />
            ) : (
              <StyledIframe title={options?.title} src={options?.src} />
            )}
            {options?.onClickAccept ? (
              <Dialog.Button.Container direction="row">
                <Dialog.Button
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    window.scrollTo(0, 0)
                    closeDialog()
                  }}
                  style={{ flex: '1 1 auto' }}
                >
                  Cancel
                </Dialog.Button>
                <Dialog.Button
                  fullWidth
                  onClick={() => {
                    closeDialog()
                    options?.onClickAccept && options.onClickAccept()
                  }}
                  style={{ flex: '1 1 auto' }}
                >
                  Accept
                </Dialog.Button>
              </Dialog.Button.Container>
            ) : (
              <Dialog.Button.Container direction="column">
                <Dialog.Button onClick={closeDialog}>Close</Dialog.Button>
              </Dialog.Button.Container>
            )}
          </>
        )}
      </Dialog>
    )
  }

  return null
}

export default LegalAgreementDialog
