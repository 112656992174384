import { ENV } from '@root/config'
import { https } from '@root/utils'

interface VerifyContactResponse {
  code: string
  message: string
  data: {
    phoneNumber: string
    sessionId: string
  }
}

interface ChallengeBody {
  sessionId: string
}

export interface ChallengeResponse {
  code: string
  message: string
  data: {
    tokenId: string
    remainingChallenge: number
    remainingResend: number
  }
}

const baseUrl = `${ENV.API_URL}/contact`

const verifyContact = async (
  phoneNumber: string
): Promise<VerifyContactResponse> => {
  const response = await (
    await https({ expectErrorData: true, errorTitle: '[verifyContact]' })
  ).get(`${baseUrl}/verify?phoneNumber=${phoneNumber}`)
  return response.data
}

const challenge = async (body: ChallengeBody): Promise<ChallengeResponse> => {
  const response = await (
    await https({ errorTitle: '[challenge]' })
  ).post(`${baseUrl}/verify`, body)
  return response.data
}

const contactApi = {
  verifyContact,
  challenge,
}

export default contactApi
