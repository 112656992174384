import { datadogRum } from '@datadog/browser-rum'
import { ENV } from '@root/config'
import { POD_NAME } from '@root/constants/team'

const browserRum = {
  init: () => {
    datadogRum.init({
      clientToken: ENV.DATADOG.clientToken,
      applicationId: ENV.DATADOG.appId,
      site: ENV.DATADOG.site,
      env: ENV.DATADOG.env,
      service: ENV.DATADOG.service,
      version: ENV.APP_VERSION,
      storeContextsAcrossPages: true,
      sessionSampleRate: 100,
      sessionReplaySampleRate: ENV.APP_ENV === 'production' ? 100 : 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
      allowedTracingUrls: [
        {
          match: ENV.API_URL,
          propagatorTypes: ['datadog'],
        },
        {
          match: ENV.AUTH_API_URL,
          propagatorTypes: ['datadog'],
        },
      ],
      traceSampleRate: ENV.APP_ENV === 'production' ? 100 : 20,
    })
  },
  setUser: (userId: string) => {
    datadogRum.setUser({ id: userId })
  },
  clearUser: () => {
    datadogRum.clearUser()
  },
  addRumGlobalContext: (key: string, value: string) => {
    datadogRum.setGlobalContextProperty(key, value)
  },
  addError: (stackTrace: string, team: POD_NAME) => {
    datadogRum.addError(stackTrace, { team })
  },
}

export { browserRum }
