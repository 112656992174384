import { https } from '@root/utils'

interface IGetPdfDocumentResponse {
  code: string
  data: IPdfDocumentData
  message: string
}

interface IPdfDocumentData {
  document: IPdfDocument
}

interface IPdfDocument {
  data: ArrayBuffer
}

const postGetPdfDocument = async (
  docId: string
): Promise<IGetPdfDocumentResponse> => {
  const response = await (
    await https({
      errorTitle: '[postGetPdfDocument]',
    })
  ).post('/lionsign/v2/documentShow', { docId })
  return response.data
}

const lionSignApi = {
  postGetPdfDocument,
}

export default lionSignApi
