import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import { ENV } from '@root/config'
import { MetaTagsProps } from '@root/types'
import {
  MARKETPLACE_PERSONAL_LOAN_PATHS,
  UNPROTECTED_PAGES_PATHS,
} from '@root/constants'

const MetaTags = ({
  title,
  category = 'Dashboard',
  description,
  imageURL,
  url,
}: MetaTagsProps): JSX.Element => {
  const router = useRouter()
  const titleTemplate = `${title} | MoneyLion ${category}`
  const metaDesc =
    description ||
    'Get smart about your finances. Our personal loans and easy to use tools help you manage your spending, savings and credit so you can achieve financial wellness.'
  const metaURL = url || `${ENV.MONEYLION_WEBAPP_URL}${router.asPath}`
  const blacklistedAppleBannerPages = [
    UNPROTECTED_PAGES_PATHS.forgotPassword,
    UNPROTECTED_PAGES_PATHS.forgotPasswordConfirm,
    MARKETPLACE_PERSONAL_LOAN_PATHS.home, // we want to disable the apple banner on the marketplace flow
  ]
  const hasBlacklistedAppleBanner = blacklistedAppleBannerPages.some((page) =>
    router.pathname.startsWith(page)
  )

  return (
    <>
      <meta charSet="utf-8" />
      <NextSeo
        title={title}
        titleTemplate={titleTemplate}
        description={metaDesc}
        canonical={metaURL}
        nofollow={ENV.APP_ENV !== 'production'}
        noindex={ENV.APP_ENV !== 'production'}
        openGraph={{
          url: metaURL,
          locale: 'en_US',
          type: 'website',
          siteName: 'MoneyLion',
          images: [
            {
              url:
                imageURL ||
                'https://moneylion.nyc3.cdn.digitaloceanspaces.com/wp-content/uploads/2022/11/09185329/moneylion-the-only-app-you-need.png',
              alt: title,
            },
          ],
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
            sizes: '32x32',
          },
        ]}
      />
      {/* Implement Apple Smart Banner */}
      {!hasBlacklistedAppleBanner && (
        <Head>
          <meta
            name="apple-itunes-app"
            content={`app-id=1064677082, app-argument=${router.pathname}`}
          />
        </Head>
      )}
    </>
  )
}

export { MetaTags }
