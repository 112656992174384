//#region Cta
interface CtaProperties {
  id: string
  key: string
  cta: string
  ctaHandler: CtaHandler
  webCtaHandler?: CtaHandler
  title?: string | null
  subtitle?: string | null
  image?: string | null
  contentTagIds: string[]
}

type CtaHandler = string // Allows for better clarity in reuse
//#endregion

//#region Today Story
interface Transaction {
  amount: number
  description: string
  date: string
}

interface TransactionData {
  transactions: Transaction[]
  transactionEndDate: string
  transactionStartDate: string
  title?: string | null
  cta?: string | null
  ctaHandler?: CtaHandler | null
  partner?: string
}

interface StoryScreen {
  disclosure: Disclosure
  image?: string
  key?: string
  primaryCta?: string
  primaryCtaHandler?: CtaHandler
  screenTime?: number
  secondaryCta?: string
  secondaryCtaHandler?: CtaHandler
  text?: string
  transactionsScreen?: TransactionData
  video?: string
  videoProperties?: VideoProperties
}

interface VideoProperties {
  id?: string
  caption?: string
  downloadUrl?: string
  key?: string
  playbackId?: string
  streamUrl?: string
  thumbnailTime?: number
  title?: string
  ctaProperties: CtaProperties[] | null
  thumbnailImage?: string
  transcriptRankedKeywords?: string[] | null
}

interface Disclosure {
  copy?: string
  url?: string
}
//#endregion

//#region Default Card Content
interface CardContent {
  id: string
  key: string
  isQa: boolean
}

interface CardProperties {
  copy: string
  title: string
  image?: string
  video?: string
  videoProperties?: VideoProperties
  ctaHandler?: CtaHandler
  storyScreens: StoryScreen[]
  contentTags: string[]
  treatments: string[]
  reaction?: string | null
  ctaProperties?: CtaProperties[] | null
  heartCount: number
  cardFirstPublishedDate?: string
  displayComments: boolean
  lockComments: boolean
}

interface ContentHeader {
  key: string
  ctaProperties?: CtaProperties[]
  icon?: string
  title?: string
  subtitle?: string
  ctaHandler?: CtaHandler
}

interface ContentDesign {
  key: string
  designStyle: string
  mediaAspectRatio: string
}
//#endregion

//#region VerticalCard
interface VerticalCard extends CardContent {
  type: 'VerticalCard'
  properties: VerticalCardProperties
}

interface VerticalCardProperties extends CardProperties {
  icon?: string
  cta?: string
  contentHeader?: ContentHeader | null
  contentDesign?: ContentDesign
  transactionData?: TransactionData | null
  reaction?: string | null
}
//#endregion

//#region SurveyCard
interface SurveyCardV3 extends CardContent {
  type: 'SurveyCard'
  properties: SurveyCardV3Properties
}

interface SurveyCardV3Properties extends CardProperties {
  answersType: string
  expirationDate: string | null
  overline?: string
  surveyAnswers: SurveyAnswer[]
  surveyResults: SurveyResult[]
  totalVotes: number
  resultCopy: string
}

interface SurveyAnswer {
  id: string
  key: string
  copy: string
  ctahandler?: CtaHandler | null
  selected: boolean
  correctAnswer: boolean
}

interface SurveyResult {
  key: string
  copy: string
  percentage: number
}
//#endregion

//#region Card Types
const FEED_CARD_TYPES_BASE = {
  SurveyCard: 'SurveyCard',
  VerticalCard: 'VerticalCard',
  TwitterCard: 'TwitterCard',
} as const

type FeedCardType = keyof typeof FEED_CARD_TYPES_BASE

export const FEED_CARD_TYPES: Record<FeedCardType, string> &
  Record<string, string> = FEED_CARD_TYPES_BASE
//#endregion

export type FeedCards = {
  CardContent: CardContent
  CardProperties: CardProperties
  VerticalCard: VerticalCard
  VerticalCardProperties: VerticalCardProperties
  SurveyCard: SurveyCardV3
  SurveyCardProperties: SurveyCardV3Properties
  CtaProperties: CtaProperties
}
