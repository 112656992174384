export const COMMENT_PAGE_SIZE = 20

export enum TODAY_CARD_LOCATION {
  TODAY_TAB = 'today-tab',
  UNKNOWN = 'unknown',
  DISCOVER_CARD_SCREEN = 'discover-card-screen',
  COMMENT_SCREEN = 'comment-screen',
  POST_SCREEN = 'post-screen',
  COMMUNITY_TAB = 'community-tab',
}

export enum COMMENT_AUTHOR_AVATAR_TYPE {
  PROFILE_IMAGE = 'profile_image',
  INITIALS = 'initials',
}

export enum COMMENT_AUTHOR_NAME_TYPE {
  FULLNAME_ROARTAG = 'fullname_roartag',
  ROARTAG_ONLY = 'roartag_only',
  FIRSTNAME_LASTNAMEINITIAL_ROARTAG = 'firstname_lastnameinitial_roartag',
}

export enum COMMENT_SUBSTRING_TYPE {
  MENTION = 'mention',
  TEXT = 'text',
  CTA = 'cta',
  WEALTH_TICKER = 'wealth_ticker',
  CRYPTO_TICKER = 'crypto_ticker',
}

export enum TICKER_PATH {
  wealth_ticker = 'stock',
  crypto_ticker = 'crypto',
}

export enum COMMENT_LINK_TYPE {
  WEALTH = 'WEALTH',
  CRYPTO = 'CRYPTO',
  SECTOR = 'SECTOR',
  WEB_LINK = 'WEB_LINK',
  CHAT_SUPPORT = 'CHAT_SUPPORT',
  CTA = 'CTA',
}

export enum COMMENT_FILE_TYPE {
  IMAGE = 'IMAGE',
  GIF = 'GIF',
}

export enum COMMENT_USER_STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  FLAGGED = 'FLAGGED',
  SUSPENDED = 'SUSPENDED',
}

export enum COMMUNITY_GUIDELINES_STATUS {
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum COMMENT_MIME_TYPE {
  IMAGE_GIF = 'image/gif',
}

export enum REPORT_COMMENT_TYPE {
  REPORT_COMMENT = 'REPORT_COMMENT',
  REPORT_USER = 'REPORT_USER',
}

export enum COMMENT_TYPE {
  DISCOVER_CARD = 'DISCOVER_CARD',
  WEALTH_TEARSHEET = 'WEALTH_TEARSHEET',
  CRYPTO_TEARSHEET = 'CRYPTO_TEARSHEET',
  TRENDING_NEWS = 'TRENDING_NEWS',
  CRYPTO_TRENDING_NEWS = 'CRYPTO_TRENDING_NEWS',
  USER_POST = 'USER_POST',
}

export enum COMMENT_SORT_TYPE {
  TOP_ENGAGED = 'TOP_ENGAGED',
  MOST_RELEVANT = 'MOST_RELEVANT',
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
}

export const WATCH_LIST_API_PATHS = {
  SEARCH: '/watchlist/search',
}

export const UNIVERSAL_SEARCH_API_PATHS = {
  UNIVERSAL_SEARCH: '/search/v1/search',
}

export const COMMENTS_API_PATHS = {
  ADD_COMMENT: '/discover/v1/comment',
  GET_COMMENT: (commentId: string) => `/discover/v1/comment/${commentId}`,
  GET_COMMENTS: (contentId: string) =>
    `/discover/v1/comment/${contentId}/comments`,
  GET_AUTHOR: (authorId: string) => `/discover/v1/user/author/${authorId}`,
  GET_LAST_UPDATED: (contentId: string) =>
    `/discover/v1/comment/${contentId}/comments/lastUpdated`,
  GET_COMMENT_COUNT: (contentId: string) =>
    `/discover/v1/comment/count/${contentId}`,
  GET_COMMENT_COUNTS: '/discover/v1/comment/counts',
  REPORT_COMMENT: '/discover/v1/moderation/report',
  DELETE_COMMENT: '/discover/v1/comment',
  LIKE_COMMENT: '/discover/v1/like',
  GET_REPLIES: (parentId: string) => `/discover/v1/reply/${parentId}/replies`,
  GET_REPLIES_LAST_UPDATED: (parentId: string) =>
    `/discover/v1/reply/${parentId}/replies/lastUpdated`,
  GET_USER_STATUS: '/discover/v1/user/status',
  UPDATE_COMMUNITY_GUIDELINES: '/discover/v1/user/communityguidelines/read',
  ADD_COMMENT_BOOST: '/discover/v1/boost',
  GET_COMMENT_BOOST_COUNT: (commentId: string) =>
    `/discover/v1/boost/count/${commentId}`,
  GET_COMMENT_BOOST_USERS: (commentId: string) =>
    `/discover/v1/boost/users/${commentId}`,
  GET_TOP_ENGAGED: '/discover/v1/comment/topEngaged',
  GET_COMMENT_LIKES: (commentId: string) =>
    `/discover/v1/comment/${commentId}/likes`,
  GET_COMMENT_SHARES: (commentId: string) =>
    `/discover/v1/comment/${commentId}/shares`,
  SHARE_COMMENT: '/discover/v1/share',
}

export const COMMENT_STATUS_CODE = {
  DUPLICATE_REPORT: 409,
  FAILED_MODERATION: 600,
  SPAM_DETECTED: 429,
  USER_SUSPENDED: 403,
}
