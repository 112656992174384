import axios, { AxiosRequestHeaders, AxiosResponse, Method } from 'axios'
import { generateUniqueDeviceId } from '@root/helpers'
import { ENV } from '@root/config'
import { useAuthStore } from '@root/store'
import { segmentApi } from '@root/api'
import { validateToken } from './validateToken'
import { DDLogger } from './logger'
import { refreshAccessToken } from './refreshAccessToken'

export interface HttpClientRequestOptions {
  shouldCheckToken?: boolean
  expectErrorData?: boolean
  useAuthApi?: boolean
  signal?: AbortSignal
  errorTitle: string
  customHeaders?: CustomHeaders
  method: Method
  body?: object
  params?: any
}

interface MakeRequest {
  url: string
  method: Method
  data?: object
  params?: any
}

// make customHeaders as flexible as possible
type CustomHeaders = Record<string, any>

let refreshAccessTokenTimeout: NodeJS.Timeout

async function handleHeadersRequest({
  useAuthApi = false,
  shouldCheckToken = true,
  signal,
  expectErrorData = false,
  errorTitle,
  customHeaders = {},
  method,
  body,
  params,
}: HttpClientRequestOptions): Promise<Partial<AxiosRequestHeaders>> {
  const headers: Partial<AxiosRequestHeaders> = customHeaders
  const token = useAuthStore.getState().getAccessToken()
  const tokenIssuer = useAuthStore.getState().getTokenIssuer()

  headers['Content-Type'] = 'application/json'
  headers['Accept'] = 'application/json'
  headers['x-app-source'] = 'webApp'
  headers['x-device-id'] = await generateUniqueDeviceId()
  headers['x-anonymous-id'] = segmentApi.getAnonId()

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (tokenIssuer !== 'MoneyLion') {
    headers['x-use-common-auth'] = '1'
  }

  if (
    ENV.APP_ENV !== 'production' &&
    customHeaders.columnTaxBackdoorSampleUser
  ) {
    headers['x-use-columntax-backdoor-user'] =
      customHeaders.columnTaxBackdoorSampleUser
  }

  return {
    headers,
    token,
    shouldCheckToken,
    errorTitle,
    useAuthApi,
    expectErrorData,
    signal,
    method,
    body,
    params,
  }
}

const httpsClient = async <T = any>(
  endpoint: string,
  options: HttpClientRequestOptions
): Promise<AxiosResponse<T>> => {
  const {
    headers,
    token,
    shouldCheckToken,
    errorTitle,
    useAuthApi,
    signal,
    expectErrorData,
    method,
    body,
    params,
  } = await handleHeadersRequest(options)

  const removeAccessToken = useAuthStore.getState().removeAccessToken
  const setAccessToken = useAuthStore.getState().setAccessToken

  const axiosInstance = axios.create({
    baseURL: useAuthApi ? ENV.AUTH_API_URL : ENV.API_URL,
    headers,
    timeout: 60000,
    timeoutErrorMessage: 'TIMEOUT',
    signal,
  })

  const request: MakeRequest = {
    url: endpoint,
    method: method,
  }

  if (body) request['data'] = body
  if (params) request['params'] = params

  try {
    const response = await axiosInstance.request<T>({
      url: request?.url,
      method: request?.method,
      data: request?.data,
      params: request?.params,
    })

    if (shouldCheckToken && !validateToken(token || '')) {
      removeAccessToken()
      window.location.reload() // if token expired refresh the page to log out user.
      throw new Error('INVALID_TOKEN_OR_EXPIRED')
    }

    const xAuthToken = response.headers['x-auth']

    if (shouldCheckToken) {
      if (xAuthToken && xAuthToken !== token) {
        setAccessToken(xAuthToken)
        refreshAccessToken(xAuthToken)
      } else if (token && refreshAccessTokenTimeout === undefined) {
        refreshAccessToken(token)
      }
    }

    return response
  } catch (error: any) {
    // This error corresponds to unauthorized access on the express backend
    if (error.response?.data?.code === 'G001') {
      DDLogger.info(errorTitle, 'Unauthorized access')
      removeAccessToken()
      window.location.reload() // if token invalid refresh the page to log out user.
    }

    if (error.response?.status === 408) {
      DDLogger.info(errorTitle, 'Request timeout')
      throw new Error('TIMEOUT')
    }

    if (expectErrorData) {
      DDLogger.info(errorTitle, 'Expect error data')
      throw error.response?.data || error.response
    }

    DDLogger.error(
      errorTitle,
      `Reject error response: ${
        JSON.stringify(error.response?.data) || JSON.stringify(error)
      }`
    )
    return Promise.reject(error.response?.data || error)
  }
}

export { httpsClient }
