import IPlus, {
  IInvestmentRiskQuestion,
  IInvestmentRiskAnswerRequest,
  IInvestmentRiskAnswerData,
  ICreateInvestmentAccountData,
} from '@root/types/Plus'
import { https } from '@root/utils'

interface IFetchPlusAccount {
  data: IPlus
}

interface IInvestmentRiskQuestionResponse {
  code: string
  data: IInvestmentRiskQuestion[]
  message: string
}

interface IInvestmentRiskAnswerResponse {
  code: string
  data: IInvestmentRiskAnswerData
  message: string
}

interface ICreateInvestmentAccountResponse {
  code: string
  data: ICreateInvestmentAccountData
  message: string
}

interface IGetInvestmentRiskScoreResponse {
  code: string
  data: IInvestmentRiskAnswerData
  message: string
}

/**
 * @deprecated: Migrate to '@investment/api'
 */
const getPlusAccount = async (): Promise<IFetchPlusAccount> => {
  const response = await (
    await https({
      errorTitle: '[getPlusAccount]',
    })
  ).get('/plus/account')
  return response.data
}

/**
 * @usage: For the Investment Flow
 * @description: Step 1 - Get the Investment risk questions
 * @returns {IInvestmentRiskQuestion[]}: Returns the Investment risk questions
 */
const getInvestmentRiskQuestions =
  async (): Promise<IInvestmentRiskQuestionResponse> => {
    const response = await (
      await https({
        errorTitle: '[getInvestmentRiskQuestions]',
      })
    ).get('/onboarding/investment/v2/risk/questions')
    return response.data
  }

/**
 * @usage: For the Investment Flow
 * @description: Step 2 - Submit the Investment risk answers
 * @param {request[answers]}: answers is IInvestmentRiskAnswers
 * @returns {IInvestmentRiskAnswerData}: Returns the risk score
 */
const putInvestmentRiskAnswers = async (
  request: IInvestmentRiskAnswerRequest
): Promise<IInvestmentRiskAnswerResponse> => {
  const response = await (
    await https({
      errorTitle: '[putInvestmentRiskAnswers]',
    })
  ).put('/onboarding/investment/v2/risk/answers', request)
  return response.data
}

/**
 * @usage: For the Investment Flow
 * @description: Step 3 - Create the Investment account
 * @returns {ICreateInvestmentAccountData}: Returns the account creation status
 */
const postCreateInvestmentAccount =
  async (): Promise<ICreateInvestmentAccountResponse> => {
    const response = await (
      await https({
        errorTitle: '[postCreateInvestmentAccount]',
      })
    ).post('/onboarding/investment/v1/accounts')
    return response.data
  }

/**
 * @usage: For the Investment Flow
 * @description: Step 4 - Get the Investment risk score
 * @returns {IInvestmentRiskAnswerData}: Returns the risk score
 */
const getInvestmentRiskScore =
  async (): Promise<IGetInvestmentRiskScoreResponse> => {
    const response = await (
      await https({
        errorTitle: '[getInvestmentRiskScore]',
      })
    ).get('/onboarding/investment/v2/risk/score')
    return response.data
  }

const plusApi = {
  getPlusAccount,
  getInvestmentRiskQuestions,
  putInvestmentRiskAnswers,
  postCreateInvestmentAccount,
  getInvestmentRiskScore,
}

export default plusApi
