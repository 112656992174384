import { create } from 'zustand'
import { CategoriesV2Card, CategoriesV2Data } from '@root/types/Marketplace'

interface MPLInitialState {
  activeCategory: CategoriesV2Data | undefined
  activeCategoryCards: CategoriesV2Card[]
}

export interface MPLState extends MPLInitialState {
  setActiveCategory: (category: CategoriesV2Data) => void
  setActiveCategoryCards: (cards: CategoriesV2Card[]) => void
  reset: () => void
}

const defaultState: MPLInitialState = {
  activeCategoryCards: [],
  activeCategory: undefined,
}

const useMPLStore = create<MPLState>()((set) => ({
  ...defaultState,
  setActiveCategory: (category: CategoriesV2Data) => {
    set({ activeCategory: category })
  },
  setActiveCategoryCards: (cards: CategoriesV2Card[]) => {
    set((state) => ({ ...state, activeCategoryCards: cards }))
  },
  reset: () => {
    set(() => {
      return {
        ...defaultState,
      }
    })
  },
}))

export { useMPLStore }
