import notFound404 from 'public/assets/svgs/404NotFound.svg'
import internalServerError500 from 'public/assets/svgs/500InternalServerError.svg'

interface ErrorLabels {
  title: string
  description: string
  returnButton: string
  imageSrc: string
}

export const ERROR_LABELS_404: ErrorLabels = {
  title: 'Oops! Page Not Found.',
  description:
    'The page that you are seeking for is in another dimension. We are working on a portal to bring it back.',
  returnButton: 'Back to MoneyLion',
  imageSrc: notFound404,
}

// Will modify later on for internal server error content
export const ERROR_LABELS_500: ErrorLabels = {
  title: `Oops! looks like something went wrong`,
  description: `The page you're seeking is in another dimension. We're working on creating a portal to bring it back.`,
  returnButton: 'Back to MoneyLion',
  imageSrc: internalServerError500,
}
