import { create } from 'zustand'
import { AddCardErrorLabelType } from '@root/components/AddCard/types'
import {
  INSTACASH_BVLINKING_NON_QUALIFIED_TEST_VALUES,
  INSTACASH_BVLINKING_QUALIFIED_TEST_VALUES,
  INSTACASH_BVLINKING_TRIAL_TIER_TEST_VALUES,
  CBPLUS_BVLINKING_TEST_VALUES,
} from '@root/constants/testValues'
import { TCashflowStatus } from '@root/types/CashFlow'
import { BudgetState } from '@root/experiences/Accounts/features/Budget/types'
import { TOnboardStateRoarmoneyIfsType } from '@root/types/Error'
import { BUDGET_STATE } from '@budget/types'

export interface CBPlusTestValues {
  bypassCheckUniqueLoanAndUserSsnCountByAccountNumberRule: boolean
  clientCountForBankAccount: number
  forceBvAuthFailure: boolean
  forceBvIdentityToThrowException: boolean
  forceExecuteLasLoTulScore: boolean
  forceInfoIdentityCheckSuccess: boolean
  highestAccountBalance: number
  highestEstimatedIncome: number
  isReturningCBPlusCustomer: boolean
  lasLoTulScore: number
  loanAppBadLoanCount: number
  loanAppUniqueSsnCount: number
  verificationIdDocumentStatus: string
}

export interface RoarMoneyTestValues {
  bvAccountDaysSinceRefresh: number
  clientCountForBankAccount: number
  loanAppBadLoanCount: number
  loanAppUniqueSsnCount: number
}

export type InstacashTestValuesType = 'qualified' | 'trialTier' | 'nonQualified'

export interface InstacashTestValues {
  accountAge: number
  authAccountNumber: string
  authRoutingNumber: string
  clientCountForBankAccount: number
  forceBvAuthFailure: boolean
  forceBvAuthSuccess: boolean
  forceInfoIdentityCheckSuccess: boolean
  forceWalletInfoIdentityCheckSuccess: boolean
  forceHasGiactReport: boolean
  giactAccountResponseCode: string
  lasic2Score: number
  loanAppBadLoanCount?: number
  loanAppUniqueSsnCount: number
  modelVariant: number
  pcd4IncomeActive: boolean
  scoreType: string
}

export type PostWowPaymentTestValue =
  | 'DEBIT_CARD_API_ERROR'
  | 'DEBIT_CARD_API_TIMEOUT'
  | 'PENDING_PAYMENT'

interface BackdoorInitialStore {
  shouldPrefill: boolean
  isBypassCaptchaBackdoorEnabled: boolean
  onboardStateRoarmoneyIfs?: TOnboardStateRoarmoneyIfsType
  isCbplusBvLinkingBackdoorEnabled: boolean
  cbplusBvLinkingTestValues: {
    testValues: CBPlusTestValues
    roarMoneyTestValues: RoarMoneyTestValues
  }
  isInstacashBvLinkingBackdoorEnabled: boolean
  instacashBvLinkingTestValuesType: InstacashTestValuesType
  instacashBvLinkingTestValues: {
    [key in InstacashTestValuesType]: InstacashTestValues
  }
  instacashAddCardModal?: AddCardErrorLabelType
  roarmoneyModal?: string
  budgetInAccountsBackdoorState?: BudgetState
  budgetInBudgetBackdoorState?: BUDGET_STATE
  isEditTransactionFailureBackdoorEnabled?: boolean
  cashflowBackdoorState?: TCashflowStatus
  postWowPaymentTestValue?: PostWowPaymentTestValue
  cmOnboardingSsnTestValue?: string
}

interface BackdoorStore extends BackdoorInitialStore {
  setShouldPrefill: (shouldPrefill: boolean) => void
  setIsBypassCaptchaBackdoorEnabled: (
    isBypassCaptchaBackdoorEnabled: boolean
  ) => void
  setOnboardStateRoarmoneyIfs: (
    onboardStateRoarmoneyIfs?: TOnboardStateRoarmoneyIfsType
  ) => void
  setIsCbplusBvLinkingBackdoorEnabled: (isEnabled: boolean) => void
  setIsInstacashBvLinkingBackdoorEnabled: (isEnabled: boolean) => void
  setInstacashBvLinkingTestValuesType: (type: InstacashTestValuesType) => void
  setInstacashAddCardModal: (
    instacashAddCardModal?: AddCardErrorLabelType
  ) => void
  setRoarmoneyModal: (roarmoneyModal?: string) => void
  setBudgetInAccountsBackdoorState: (
    budgetInAccountsBackdoorState?: BudgetState
  ) => void
  setBudgetInBudgetBackdoorState: (
    budgetInBudgetBackdoorState?: BUDGET_STATE
  ) => void
  setIsEditTransactionFailureBackdoorEnabled: (isEnabled: boolean) => void
  setCashflowBackdoorState: (cashflowBackdoorState?: TCashflowStatus) => void
  setPostWowPaymentTestValue: (
    postWowPaymentTestValue?: PostWowPaymentTestValue
  ) => void
  setCMOnboardingSsnTestValue: (cmOnboardingTestValue?: string) => void
  reset: () => void
}

const initialState: BackdoorInitialStore = {
  shouldPrefill: false,
  isBypassCaptchaBackdoorEnabled: false,
  onboardStateRoarmoneyIfs: undefined,
  isCbplusBvLinkingBackdoorEnabled: false,
  cbplusBvLinkingTestValues: CBPLUS_BVLINKING_TEST_VALUES,
  isInstacashBvLinkingBackdoorEnabled: false,
  instacashBvLinkingTestValuesType: 'qualified',
  instacashBvLinkingTestValues: {
    qualified: INSTACASH_BVLINKING_QUALIFIED_TEST_VALUES,
    trialTier: INSTACASH_BVLINKING_TRIAL_TIER_TEST_VALUES,
    nonQualified: INSTACASH_BVLINKING_NON_QUALIFIED_TEST_VALUES,
  },
  instacashAddCardModal: undefined,
  roarmoneyModal: undefined,
  budgetInAccountsBackdoorState: undefined,
  budgetInBudgetBackdoorState: undefined,
  isEditTransactionFailureBackdoorEnabled: false,
  cashflowBackdoorState: undefined,
  postWowPaymentTestValue: undefined,
  cmOnboardingSsnTestValue: undefined,
}

const useBackdoorStore = create<BackdoorStore>()((set) => ({
  ...initialState,
  setShouldPrefill: (shouldPrefill) => {
    set({ shouldPrefill })
  },
  setIsBypassCaptchaBackdoorEnabled: (isBypassCaptchaBackdoorEnabled) => {
    set({ isBypassCaptchaBackdoorEnabled })
  },
  setOnboardStateRoarmoneyIfs: (onboardStateRoarmoneyIfs) => {
    set({ onboardStateRoarmoneyIfs })
  },
  setIsCbplusBvLinkingBackdoorEnabled: (isEnabled) => {
    set({
      isCbplusBvLinkingBackdoorEnabled: isEnabled,
    })
  },
  setIsInstacashBvLinkingBackdoorEnabled: (isEnabled) => {
    set({
      isInstacashBvLinkingBackdoorEnabled: isEnabled,
    })
  },
  setInstacashBvLinkingTestValuesType: (type) => {
    set({
      instacashBvLinkingTestValuesType: type,
    })
  },
  setInstacashAddCardModal: (instacashAddCardModal) => {
    set({ instacashAddCardModal })
  },
  setRoarmoneyModal: (roarmoneyModal) => {
    set({ roarmoneyModal })
  },
  setBudgetInAccountsBackdoorState: (budgetInAccountsBackdoorState) => {
    set({ budgetInAccountsBackdoorState })
  },
  setBudgetInBudgetBackdoorState: (budgetInBudgetBackdoorState) => {
    set({ budgetInBudgetBackdoorState })
  },
  setIsEditTransactionFailureBackdoorEnabled: (isEnabled) => {
    set({ isEditTransactionFailureBackdoorEnabled: isEnabled })
  },
  setCashflowBackdoorState: (cashflowBackdoorState) => {
    set({ cashflowBackdoorState })
  },
  setPostWowPaymentTestValue: (postWowPaymentTestValue) => {
    set({ postWowPaymentTestValue })
  },
  setCMOnboardingSsnTestValue: (cmOnboardingSsnTestValue) => {
    set({ cmOnboardingSsnTestValue })
  },
  reset: () => {
    set(initialState)
  },
}))

export { useBackdoorStore }
