import { create } from 'zustand'
import {
  appsFlyerApi,
  checkingAccountApi,
  segmentApi,
  userApi,
  bankingApi,
} from '@root/api'
import ICheckingAccount from '@root/types/CheckingAccount'
import { useOnboardingStore } from '@root/store/onboardingStore'
import { useUserStore } from '@root/store/userStore'

interface IRoarMoney {
  accountNumber: string
  availableBalance: number
}

interface RoarMoneyInitialState {
  isLoading?: boolean
  roarMoney?: IRoarMoney | null
}
export interface RoarMoneyState extends RoarMoneyInitialState {
  getRoarMoneyDetails: () => Promise<ICheckingAccount | undefined>
  createRMCheckingAccount: (
    jobCode: number,
    incomeCode: number,
    paymentTypeCode: string,
    signal?: AbortSignal
  ) => Promise<string | undefined>
  getReferrer: () => string
  reset: () => void
}

const initialState: RoarMoneyInitialState = {
  isLoading: false,
  roarMoney: undefined,
}
const useRoarMoneyStore = create<RoarMoneyState>()((set) => ({
  ...initialState,
  /**
   * @error: Sample
   * {
   *    code: "404",
   *    data: {
   *      requestErrors: [
   *        "No virtual account found for ml user ID 64b8f8520891db718fbeab5e"
   *      ]
   *    }
   * }
   */
  getRoarMoneyDetails: async () => {
    try {
      set({ isLoading: true })

      const resp = await checkingAccountApi.fetchCheckingAccount()

      if (resp.data) {
        set({
          isLoading: false,
          roarMoney: {
            availableBalance: resp?.data.availableBalance || 0,
            accountNumber: resp?.data.achInformation?.accountNumber || '',
          },
        })
      }

      return resp.data
    } catch (error: any) {
      // If user do not have account
      if (error?.code === 404) {
        set({ isLoading: false, roarMoney: null })
      } else {
        set({ isLoading: false })
      }

      return Promise.reject(error)
    }
  },
  createRMCheckingAccount: async (
    jobCode: number,
    incomeCode: number,
    paymentTypeCode: string
  ) => {
    const getReferrer = useRoarMoneyStore.getState().getReferrer

    const response = await userApi.getUserProfile()

    if (
      !response ||
      !response.data ||
      !response.data.address ||
      !response.data.address.id
    ) {
      throw new Error('MISSING_VALUES')
    }

    const { data } = await bankingApi.createCheckingAccount({
      depositCode: incomeCode,
      employmentCode: jobCode,
      addressId: response.data.address.id,
      leadGenTransactionId:
        useUserStore.getState().affiliateDetails?.leadGenTransactionId || '',
      paymentForm: paymentTypeCode,
    })

    const message = data && data.message

    if (message === 'Created') {
      segmentApi.trackWithOSPlatform('rm_account_create_submit', {
        status: 'success',
        referrer: getReferrer(),
      })
      segmentApi.trackWithOSPlatform('DDA Account Created')
      appsFlyerApi.event('RM Account Created')
    } else {
      segmentApi.trackWithOSPlatform('rm_account_create_submit', {
        status: 'fail',
        fail_reason: message,
        referrer: getReferrer(),
      })
    }

    return message
  },
  getReferrer: () => {
    const isTaxFilingFlow = useOnboardingStore.getState().isTaxFilingFlow
    const isIntentFlow = useOnboardingStore.getState().isIntentFlow

    if (isTaxFilingFlow()) return 'tax_filing'
    if (isIntentFlow) return 'intent'
    return 'organic'
  },
  reset: () => {
    set(initialState)
  },
}))

export { useRoarMoneyStore }
