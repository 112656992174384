import { NextWebVitalsMetric } from 'next/app'
import { segmentApi } from '@root/api'

export interface WebVitalsEventParams {
  id: string
  name: string
  value: number
  debug_target?: unknown
}

const reportNextWebVitals = (metric: NextWebVitalsMetric): void => {
  const { id, name, value, attribution } = metric

  const eventParams: WebVitalsEventParams = {
    id,
    name,
    value: Number((value / 1000).toFixed(3)),
  }

  switch (name) {
    case 'Next.js-hydration':
      window.nextHydration = eventParams
      break
    case 'Next.js-render':
      window.nextRender = eventParams
      break
    case 'Next.js-route-change-to-render':
      window.nextRouteChangeToRender = eventParams
      break
    case 'CLS':
      segmentApi.trackWithOSPlatform('webdash_cls_detected', {
        CLS: {
          ...eventParams,
          debug_target: attribution?.largestShiftTarget,
        },
      })
      break
    case 'FCP':
      window.nextFcp = eventParams
      break
    case 'FID':
      window.nextFid = {
        ...eventParams,
        debug_target: attribution?.eventTarget,
      }
      break
    case 'LCP':
      window.nextLcp = {
        ...eventParams,
        debug_target: attribution?.url,
      }
      break
    case 'TTFB':
      window.nextTtfb = eventParams
      break
    default:
      break
  }

  const FCP = window.nextFcp
  const TTFB = window.nextTtfb
  const isFirstPageMetricSent = window.nextIsFirstPageMetricSent
  const LCP = window.nextLcp
  const FID = window.nextFid
  const isPageInteractedMetricSent = window.nextIsPageInteractedMetricSent

  if (FCP && TTFB && !isFirstPageMetricSent) {
    segmentApi.trackWithOSPlatform('webdash_first_page_landed', {
      FCP,
      TTFB,
    })
    window.nextIsFirstPageMetricSent = true
  }

  if (LCP && FID && !isPageInteractedMetricSent) {
    segmentApi.trackWithOSPlatform('webdash_first_interaction', {
      LCP,
      FID,
    })
    window.nextIsPageInteractedMetricSent = true
  }
}

export { reportNextWebVitals }
