import {
  IBiddingClickNotifyRequest,
  IBiddingClickNotifyData,
  IBiddingCreateNotifyRequest,
  IBiddingCreateNotifyData,
} from '@root/types/Bidding'
import { https } from '@root/utils'

interface IBiddingClickNotifyResponse {
  code: string
  data: IBiddingClickNotifyData
  message: string
}

interface IBiddingCreateNotifyResponse {
  code: string
  data: IBiddingCreateNotifyData
  message: string
}

interface LeadGetUserInfoReq {
  leadUuid: string
  offerId: string
  utmSource: string
}

interface LeadGetUserInfoRes {
  data: {
    personalInformation?: {
      address1?: string
      address2?: string
      city?: string
      dateOfBirth?: string
      email?: string
      firstName?: string
      lastName?: string
      primaryPhone?: string
      ssn?: string
      state?: string
      zipcode?: string
    }
  }
}

/**
 * @usage: For the Affiliate flow, during Signup page
 * @description: Step 1 - When user lands on the Affiliate flow, notify Bidding API
 * @returns {IBiddingClickNotifyData}: Returns bidding data
 */
const postBiddingClickNotify = async (
  request: IBiddingClickNotifyRequest
): Promise<IBiddingClickNotifyResponse> => {
  // This endpoint does not require authentication
  const response = await (
    await https({
      shouldCheckToken: false,
      errorTitle: '[postBiddingClickNotify]',
    })
  ).post(`/bidding/v1/leadClickNotify`, request)

  return response.data
}

/**
 * @usage: For the Affiliate flow, during Signup page
 * @description: Step 2 - During the Affiliate flow, notify Bidding API after Lite account creation
 * @returns {IBiddingCreateNotifyResponse}: Returns the leadUuid and userId
 */
const postBiddingCreateNotify = async (
  request: IBiddingCreateNotifyRequest
): Promise<IBiddingCreateNotifyResponse> => {
  const response = await (
    await https({ errorTitle: '[postBiddingCreateNotify]' })
  ).post(`/bidding/v1/leadMLUserCreateNotify`, request)
  return response.data
}

/**
 * @usage: For the Affiliate flow, during Address and SSN page
 * @description: Step 3 - During the Affiliate flow, get user Address and SSN from Affiliate
 * @returns {LeadGetUserInfoRes}: Returns the Address and SSN
 */
const leadGetUserInfo = async (
  payload: LeadGetUserInfoReq,
  signal?: AbortSignal
): Promise<LeadGetUserInfoRes> => {
  const response = await (
    await https({ signal, errorTitle: '[leadGetUserInfo]' })
  ).post(`/bidding/v1/leadGetInfo`, payload)
  return response.data
}

const biddingApi = {
  postBiddingClickNotify,
  postBiddingCreateNotify,
  leadGetUserInfo,
}

export default biddingApi
