export const CBPLUS_BVLINKING_TEST_VALUES = {
  testValues: {
    bypassCheckUniqueLoanAndUserSsnCountByAccountNumberRule: true,
    clientCountForBankAccount: 1,
    forceBvAuthFailure: false,
    forceBvIdentityToThrowException: false,
    forceExecuteLasLoTulScore: true,
    forceInfoIdentityCheckSuccess: true,
    highestAccountBalance: 2000,
    highestEstimatedIncome: 18540.83,
    isReturningCBPlusCustomer: false,
    lasLoTulScore: 0.981,
    loanAppBadLoanCount: 0,
    loanAppUniqueSsnCount: 1,
    verificationIdDocumentStatus: 'PASSED',
  },
  roarMoneyTestValues: {
    bvAccountDaysSinceRefresh: 1,
    clientCountForBankAccount: 1,
    loanAppBadLoanCount: 0,
    loanAppUniqueSsnCount: 1,
  },
}

export const INSTACASH_BVLINKING_QUALIFIED_TEST_VALUES = {
  accountAge: 90,
  authAccountNumber: '1453627851',
  authRoutingNumber: '124356271',
  clientCountForBankAccount: 1,
  forceBvAuthFailure: false,
  forceBvAuthSuccess: true,
  forceInfoIdentityCheckSuccess: true,
  forceWalletInfoIdentityCheckSuccess: true,
  forceHasGiactReport: false,
  giactAccountResponseCode: '',
  lasic2Score: 0.8,
  loanAppBadLoanCount: undefined,
  loanAppUniqueSsnCount: 1,
  modelVariant: 0,
  pcd4IncomeActive: false,
  scoreType: 'LASIC2',
}

export const INSTACASH_BVLINKING_TRIAL_TIER_TEST_VALUES = {
  ...INSTACASH_BVLINKING_QUALIFIED_TEST_VALUES,
  accountAge: 10,
  lasic2Score: 0.65,
  loanAppBadLoanCount: 0,
  scoreType: 'LASIC2',
}

export const INSTACASH_BVLINKING_NON_QUALIFIED_TEST_VALUES = {
  ...INSTACASH_BVLINKING_TRIAL_TIER_TEST_VALUES,
  accountAge: 30,
  forceBvAuthSuccess: false,
  forceInfoIdentityCheckSuccess: false,
  lasic2Score: 0.3,
  loanAppBadLoanCount: 10,
  scoreType: 'LASIC2',
}

const ssnMax = 899999999
const ssnMin = 100000000
const ssnRandomWithDecimal = Math.random() * (ssnMax - ssnMin) + ssnMin
const ssnRandomWithoutDecimal = Math.floor(ssnRandomWithDecimal)

export const LITE_CORE_ONBOARDING_PREFILL_VALUES = {
  firstName: 'John',
  lastName: 'Smith',
  email: '@moneylion.com',
  password: 'Moneylion1!',
  confirmPassword: 'Moneylion1!',
  phoneNumber: '(650)532-3283',
  confirmationCode: '1111',
  dob: '02/28/1975',
  address: {
    streetAddress: '200 Brannan St',
    apt: 'Apt 210',
    city: 'San Francisco',
    state: 'CA',
    zip: '94107',
  },
  ssn: ssnRandomWithoutDecimal.toString(), //between 100-00-0000 ~ 899-99-9999
}
