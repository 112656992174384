import IUser from '@root/types/User'
import IReferrals, {
  IReferralSummary,
  IReferralCodeRequest,
  IReferralCodeValidationData,
  IReferralCodeSubmissionData,
} from '@root/types/Referrals'
import { https } from '@root/utils'

interface IReferralCodeValidationResponse {
  code: string
  data: IReferralCodeValidationData
  message: string
}

interface IReferralCodeSubmissionResponse {
  code: string
  data: IReferralCodeSubmissionData
  message: string
}

const fetchReferrals = async (user: IUser | null): Promise<IReferrals> => {
  const query = `userId=${user?.id}`
  const referralDataRes = await (
    await https({
      errorTitle: '[fetchReferrals]',
    })
  ).get(`/referral/referral-code?${query}`)

  if (referralDataRes.status === 200) {
    return referralDataRes?.data?.data?.data
  }
  return {}
}

const fetchReferralSummary = async (): Promise<IReferralSummary | null> => {
  const referralDataRes = await (
    await https({
      errorTitle: '[fetchReferralSummary]',
    })
  ).get(`/referral/referral-summary`)

  if (referralDataRes.status === 200) {
    return referralDataRes?.data?.data?.data
  }
  throw new Error(`Error: There has been an issue. ${referralDataRes.status}`)
}

/**
 * @usage: For the Instacash Engine Sign Up page (Onboarding)
 * @description: Step 1 - To validate the referral code or RoarTag referral code
 * @param {request[code]}: code is referral code
 * @returns {IReferralCodeValidationData}: Returns the validation result
 */
const postReferralValidateCode = async (
  request: IReferralCodeRequest
): Promise<IReferralCodeValidationResponse> => {
  const response = await (
    await https({
      shouldCheckToken: false,
      errorTitle: '[postReferralValidateCode]',
    })
  ).post(`/referral/referralCode`, request)
  return response.data
}

/**
 * @usage: For the Instacash Engine Sign Up page (Onboarding)
 * @description: Step 2 - To submit the referral code, after Lite Account Creation is completed
 * @param {request[code]}: code is referral code
 * @returns {IReferralCodeSubmissionData}: Returns the status
 */
const postSubmitReferralCodeSignUp = async (
  request: IReferralCodeRequest
): Promise<IReferralCodeSubmissionResponse> => {
  const response = await (
    await https({ errorTitle: '[postSubmitReferralCodeSignUp]' })
  ).post(`/referral/signup`, request)
  return response.data
}

/**
 * @usage: For the Referral page (Onboarding)
 * @description: Submit user referral code
 * @param {request[code]}: code is referral code
 * @returns {IReferralCodeSubmissionData}: Returns the status
 */
const submitReferral = async (
  payload: IReferralCodeRequest
): Promise<IReferralCodeSubmissionResponse> => {
  const response = await (
    await https({ expectErrorData: true, errorTitle: '[submitReferral]' })
  ).post(`/referral/userReferral`, payload)
  return response.data
}

const referralApi = {
  fetchReferrals,
  fetchReferralSummary,
  postReferralValidateCode,
  postSubmitReferralCodeSignUp,
  submitReferral,
}

export default referralApi
