import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import IPlus, {
  IInvestmentRiskQuestion,
  TInvestmentRiskQuestionKey,
  IInvestmentRiskAnswers,
  TInvestmentRiskTolerance,
} from '@root/types/Plus'
import { plusApi } from '@root/api'

interface IInvestment {
  accountNo: string
  availableBalance: number
}

interface ICreditReserve {
  accountNo: string
  availableBalance: number
}

interface IInvestmentRiskScore {
  score: number
  riskTolerance: TInvestmentRiskTolerance
}

interface InvestmentInitialState {
  investment?: IInvestment | null
  creditReserve?: ICreditReserve | null
  investmentRiskQuestions?: IInvestmentRiskQuestion[] | undefined
  investmentRiskAnswers?: IInvestmentRiskAnswers | undefined
  investmentRiskScore?: IInvestmentRiskScore | undefined
  getPlusAccountError?: any
}

export interface InvestmentState extends InvestmentInitialState {
  getInvestmentDetails: () => Promise<IPlus | undefined>
  getInvestmentRiskQuestions: () => Promise<
    IInvestmentRiskQuestion[] | undefined
  >
  getInvestmentRiskScore: () => Promise<IInvestmentRiskScore | undefined>
  setInvestmentRiskAnswers: (
    questionKey: TInvestmentRiskQuestionKey,
    answerId: string | boolean
  ) => void
  setInvestmentRiskScore: (riskScore: IInvestmentRiskScore) => void
  reset: () => void
}

const initialState: InvestmentInitialState = {
  investment: undefined,
  creditReserve: undefined,
  investmentRiskQuestions: undefined,
  investmentRiskAnswers: undefined,
  investmentRiskScore: undefined,
  /**
   * Temp error state for plusApi.getPlusAccount
   * Will be replaced with SWR soon
   */
  getPlusAccountError: undefined,
}

const useInvestmentStore = create<InvestmentState>()(
  persist(
    (set, getState) => ({
      ...initialState,
      /**
       * @error: Sample
       * {
       *    code: "400",
       *    data: {
       *      code: "U0022",
       *      message: "No drivewealth user associated with Moneylion user"
       *    },
       *    message: "Bad Request"
       * }
       */
      getInvestmentDetails: async () => {
        try {
          set({ getPlusAccountError: undefined })

          const plusDetails = await plusApi.getPlusAccount()

          let investment = null
          let creditReserve = null

          if (
            plusDetails?.data &&
            plusDetails?.data.status === 'PLUS_API_SUCCESS' &&
            plusDetails?.data.accounts
          ) {
            const { accounts } = plusDetails.data
            const investmentAccountDetails = accounts.find(
              (value) => value.accountInternalType === 'INVESTMENT'
            )

            if (investmentAccountDetails) {
              investment = {
                accountNo: investmentAccountDetails.accountNo || '',
                availableBalance:
                  investmentAccountDetails.availableBalance || 0,
              }
            }

            const escrowAccountDetails = accounts.filter(
              (value) =>
                value.accountInternalType === 'ESCROW' &&
                value.accountStatus === 'OPEN' &&
                value.availableBalance > 0
            )

            if (escrowAccountDetails.length > 1) {
              const accountWithLatestCreatedAtDate = escrowAccountDetails.sort(
                (firstEscrowAccountDetail, secondEscrowAccountDetail) =>
                  firstEscrowAccountDetail.accountCreatedOn +
                  secondEscrowAccountDetail.accountCreatedOn
              )
              creditReserve = {
                accountNo: accountWithLatestCreatedAtDate[0].accountNo || '',
                availableBalance:
                  accountWithLatestCreatedAtDate[0].availableBalance || 0,
              }
            }

            if (escrowAccountDetails.length === 1) {
              creditReserve = {
                accountNo: escrowAccountDetails[0].accountNo || '',
                availableBalance: escrowAccountDetails[0].availableBalance || 0,
              }
            }
          }

          set({ investment, creditReserve })

          return plusDetails?.data
        } catch (error: any) {
          if (error?.data?.code === 'U0022') {
            set({ investment: null, creditReserve: null })
          }
          set({ getPlusAccountError: error })

          return Promise.reject(error)
        }
      },
      getInvestmentRiskQuestions: async () => {
        const existingData = getState().investmentRiskQuestions
        if (existingData && existingData.length > 0) return existingData

        const { data } = await plusApi.getInvestmentRiskQuestions()
        set({ investmentRiskQuestions: data })
        return data as IInvestmentRiskQuestion[]
      },
      setInvestmentRiskAnswers: async (
        questionKey: TInvestmentRiskQuestionKey,
        answerId: string | boolean
      ) => {
        const state = getState()

        const newInvestmentRiskAnswers = {
          ...state.investmentRiskAnswers,
          [questionKey]: answerId,
        }

        set({
          investmentRiskAnswers: {
            ...newInvestmentRiskAnswers,
          },
        })
      },
      getInvestmentRiskScore: async () => {
        const state = getState()

        if (state.investmentRiskScore) return state.investmentRiskScore

        const { data } = await plusApi.getInvestmentRiskScore()
        const { score, riskTolerance } = data
        if (score && riskTolerance) {
          set({
            investmentRiskScore: {
              score,
              riskTolerance,
            },
          })
        }

        return data as IInvestmentRiskScore
      },
      setInvestmentRiskScore: async (riskScore: IInvestmentRiskScore) => {
        set({
          investmentRiskScore: riskScore,
        })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        investmentRiskQuestions: state.investmentRiskQuestions,
        investmentRiskAnswers: state.investmentRiskAnswers,
      }),
      name: 'investmentStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useInvestmentStore }
