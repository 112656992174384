import ErrorPage from '@root/experiences/Error'
import { MetaTags } from '@root/layouts'

const Custom500 = () => {
  return (
    <>
      <MetaTags title="500 Internal Server Error" />
      <ErrorPage type="500" />
    </>
  )
}

export default Custom500
