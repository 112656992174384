import { https } from '@root/utils'
import { BankingActiveDocument, SignBankingEsignData } from '@root/types'

interface GetBankingEsignRes {
  data: {
    activeAgreements: BankingActiveDocument[]
  }
}

interface SignBankingEsignRequest {
  documentIds: string[]
}

interface SignBankingEsignResponse {
  code: string
  data: SignBankingEsignData
  message: string
}

interface CreateCheckingAccountBody {
  depositCode: number
  employmentCode: number
  addressId: string
  leadGenTransactionId: string | null
  paymentForm: string
}

interface CreateCheckingAccountRes {
  data?: {
    message: string
  }
  status: number
  message?: string
}

interface Transaction {
  activityType: string
  activityTypeDescription: string
  description: string
  authTimestamp: string
  postTimestamp: string
  amount: number
  calculatedBalance: number
  details: string
  displayDetails: {
    thirdParty: string
    cardLastFour: string
    internalTransactionType: string
    achTransferFrom: string
    achTransferTo: string
  }
  pending: boolean
  greenDotInTransitFlag: boolean
  expirationDate: string
  achReleaseDate: string
  imageUrl: string
  autodepositId: string
  autodepositDescription: string
  p2pTransferId: string
}

interface GetTransactionsResp {
  data?: {
    transactions: Transaction[]
    currentPage: number
    totalPages: number
  }
  status: number
}

interface GetTransactionsQueryParams {
  page?: number
  pageSize?: number
  filter?: 'ALL' | 'INCOMING' | 'OUTGOING'
}

const getBankingEsign = async (
  signal?: AbortSignal
): Promise<GetBankingEsignRes> => {
  const response = await (
    await https({ signal, errorTitle: '[getBankingEsign]' })
  ).get(`/banking/esign`)
  return response.data
}

const signBankingEsign = async (
  body: SignBankingEsignRequest
): Promise<SignBankingEsignResponse> => {
  const response = await (
    await https({
      errorTitle: '[signBankingEsign]',
    })
  ).post(`/banking/esign/sign`, body)
  return response.data
}

const createCheckingAccount = async (
  body: CreateCheckingAccountBody
): Promise<CreateCheckingAccountRes> => {
  const response = await (
    await https({
      errorTitle: '[createCheckingAccount]',
    })
  ).post(`/banking/checkingAccount/create`, body)
  return response.data
}

const getTransactions = async (
  query?: GetTransactionsQueryParams,
  signal?: AbortSignal
): Promise<GetTransactionsResp> => {
  const page = query?.page || 0
  const pageSize = query?.pageSize || 5
  const filter = query?.filter || 'ALL'
  const response = await (
    await https({ signal, errorTitle: '[getTransactions]' })
  ).get(
    `/banking/transactions?page=${page}&pageSize=${pageSize}&filter=${filter}`
  )
  return response.data
}

const bankingApi = {
  getBankingEsign,
  signBankingEsign,
  createCheckingAccount,
  getTransactions,
}

export default bankingApi
