const setCustomerUserId = (customerUserId: string): void => {
  if (window.AF) {
    window.AF('pba', 'setCustomerUserId', customerUserId)
  }
}

const event = (
  eventName: string,
  eventValue: Record<string, any> = {}
): void => {
  if (window.AF) {
    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventName,
      eventValue: {
        ...eventValue,
        source: 'webApp',
      },
    })
  }
}

const appsFlyerApi = {
  setCustomerUserId,
  event,
}

export default appsFlyerApi
