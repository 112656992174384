import {
  IRecentCashAdvanceData,
  IRepaymentMethod,
  IRepaymentFundOptionsData,
  OnboardingQualificationResponse,
  IInstacashTncEsignDocuments,
  IInstacashTncEsignDocument,
  IInstacashTimeZone,
  IOutstandingCashAdvance,
} from '@root/types/CashAdvance'
import ICashAdvanceInfo from '@root/types/CashAdvanceInfo'
import { generateQueryString, https } from '@root/utils'

export interface IGetCashAdvanceInfoResponse {
  code?: string
  data: ICashAdvanceInfo
  message?: string
}

interface IGetRecentCashAdvanceResponse {
  code: string
  data: IRecentCashAdvanceData
  message: string
}

interface IGetRepaymentMethodResponse {
  code: string
  data: IRepaymentMethod[]
  message: string
}

interface IGetRepaymentFundOptionsResponse {
  code: string
  data: IRepaymentFundOptionsData
  message: string
}

interface IGetInstacashTncEsignDocumentsResponse {
  code: string
  data: IInstacashTncEsignDocuments
  message: string
}

interface IPostInstacashTncEsignDocumentsResponse {
  code: string
  data: IInstacashTncEsignDocuments
  message: string
}

interface IPutInstacashTncEsignDocumentsResponse {
  code: string
  data: IInstacashTncEsignDocuments
  message: string
}

interface IGetUserTimeZoneResponse {
  code: string
  data: IInstacashTimeZone
  message: string
}

export interface IGetOutstandingCashAdvancesResponse {
  code: string
  data: IOutstandingCashAdvance
  message: string
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: To get the recent cash advance transactions. If no transactions found, means there is no Instacash disbursement.
 * @returns {IRecentCashAdvanceData}: Returns the recent cash advance transactions
 */
const getRecentCashAdvance = async (
  pageNumber = 0,
  pageSize = 5,
  includeApplication = true,
  includePendingPayment = true,
  filterType: 'cashadvance' | 'payment' | 'application' | '' = '',
  filterSuccess = 'success'
): Promise<IGetRecentCashAdvanceResponse> => {
  const queryString = generateQueryString({
    includeApplication,
    includePendingPayment,
    filterSuccess,
    pageNumber,
    pageSize,
    filterType,
  })

  const response = await (
    await https({
      errorTitle: '[getRecentCashAdvance]',
    })
  ).get(`/cash-advance/recent?${queryString}`)
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: To get a list of Debit Cards that are linked to Instacash Repayment. If no cards found, means there is no debit card link to Instacash Repayment.
 * @returns {IRepaymentMethod[]}: Returns the recent cash advance transactions
 */
const getRepaymentMethods = async (): Promise<IGetRepaymentMethodResponse> => {
  const response = await (
    await https({ errorTitle: '[getRepaymentMethods]' })
  ).get(`/cash-advance/repayment/repayment-methods`)
  return response.data
}

/**
 * @usage:
 *  1) For displaying the Instacash info
 *  2) For the Instacash Flow
 *  3) For the Instacash Disbursement Flow
 * @description:
 *  1) To get the Cash Advance info
 *  2) To check if the user is eligible to enter a flow
 * @returns {ICashAdvanceInfo}: Returns the recent cash advance transactions
 */
const getCashAdvanceInfo = async (): Promise<IGetCashAdvanceInfoResponse> => {
  const response = await (
    await https({ errorTitle: '[getCashAdvanceInfo]' })
  ).get('/cash-advance/info')
  return response.data
}

/**
 * @usage: For the Add Card Flow
 * @description: Step 2 - Get repayment fund options
 * @returns {IGetRepaymentFundOptionsData}: Returns the repayment fund options
 */
const getRepaymentFundOptions = async (
  types?: string
): Promise<IGetRepaymentFundOptionsResponse> => {
  const urlParams = types ? `?types=${types}` : ''

  const response = await (
    await https({
      errorTitle: '[getRepaymentFundOptions]',
    })
  ).get(`/cash-advance/repayment/fundOptions${urlParams}`)
  return response.data
}

/**
 * @description Gets user onboarding qualification info
 * @returns {Promise<OnboardingQualification>}
 */
const getOnboardingQualification =
  async (): Promise<OnboardingQualificationResponse> => {
    const response = await (
      await https({ errorTitle: '[getOnboardingQualification]' })
    ).get(`/cash-advance/onboarding/qualification`)
    return response?.data
  }

/**
 * @usage: For the Instacash Terms and Conditions Esign Flow
 * @description: To fetch the user's Instacash Terms and Conditions Esign documents
 * @returns {IInstacashTncEsignDocuments}: Returns user's Instacash Terms and Conditions Esign documents
 */
const getInstacashTncEsignDocuments =
  async (): Promise<IGetInstacashTncEsignDocumentsResponse> => {
    const response = await (
      await https({ errorTitle: '[getInstacashTncEsignDocuments]' })
    ).get(`/cash-advance/user-esigns`)
    return response.data
  }

/**
 * @usage: For the Instacash Terms and Conditions Esign Flow
 * @description: Generate Instacash Terms and Conditions Esign documents
 * @returns {IInstacashTncEsignDocuments}: Returns generated Instacash Terms and Conditions Esign documents
 */
const postInstacashTncEsignDocuments =
  async (): Promise<IPostInstacashTncEsignDocumentsResponse> => {
    const response = await (
      await https({ errorTitle: '[postInstacashTncEsignDocuments]' })
    ).post('/cash-advance/user-esigns')
    return response.data
  }

/**
 * @usage: For the Instacash Terms and Conditions Esign Flow
 * @description: Update Instacash Terms and Conditions Esign documents
 * @returns {IInstacashTncEsignDocuments}: Returns updated Instacash Terms and Conditions Esign documents
 */
const putInstacashTncEsignDocuments = async ({
  esignDocuments,
}: {
  esignDocuments: IInstacashTncEsignDocument[]
}): Promise<IPutInstacashTncEsignDocumentsResponse> => {
  const response = await (
    await https({ errorTitle: '[putInstacashTncEsignDocuments]' })
  ).put('/cash-advance/user-esigns', { esignDocuments })
  return response.data
}

const getOutstandingCashAdvances =
  async (): Promise<IGetOutstandingCashAdvancesResponse> => {
    const response = await (
      await https({ errorTitle: '[getOutstandingCashAdvances]' })
    ).get(`/cash-advance/outstanding`)
    return response.data
  }

/**
 * @usage: Used to format the dates that are across all IC modules
 * @description: To fetch the user's time zone info
 * @returns {IInstacashTimeZone}: Returns user's time zone info
 */
const getUserTimeZoneInfo = async (): Promise<IGetUserTimeZoneResponse> => {
  const response = await (
    await https({ errorTitle: '[getUserTimeZoneInfo]' })
  ).get(`/cash-advance/info/time-zone`)
  return response.data
}

const instacashApi = {
  getCashAdvanceInfo,
  getRecentCashAdvance,
  getRepaymentMethods,
  getRepaymentFundOptions,
  getOnboardingQualification,
  getInstacashTncEsignDocuments,
  postInstacashTncEsignDocuments,
  putInstacashTncEsignDocuments,
  getOutstandingCashAdvances,
  getUserTimeZoneInfo,
}

export default instacashApi
