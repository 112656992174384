const get = (key: string): string | null => {
  if (typeof window === 'undefined') return null
  return window.sessionStorage.getItem(key)
}

const set = (key: string, value: string): void => {
  window.sessionStorage.setItem(key, value)
}

const remove = (key: string): void => {
  window.sessionStorage.removeItem(key)
}

const clear = (): void => {
  window.sessionStorage.clear()
}

const sessionStorageApi = {
  get,
  set,
  remove,
  clear,
}

export default sessionStorageApi
