import {
  initiateFingerprint,
  getFingerprint,
  clearFingerprint,
} from './fingerprint'

export const deviceIdUtils = {
  initiateFingerprint,
  getFingerprint,
  clearFingerprint,
}
