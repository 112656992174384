import { SPLIT_FEATURE_FLAG } from '@root/constants'
import { useSplitStore } from '@root/store'
import IBank, {
  BankSource,
  GetTopBanksRequest,
  GetSearchBanksRequest,
  BvLinkingInitiateRequest,
  BvLinkingInitiateData,
  IBankInfo,
  IBankSummary,
} from '@root/types/Bank'
import { httpsClient } from '@root/utils'

export interface IFetchTopBanksResp {
  data: {
    institutions: IBank[]
  }
}

interface BvLinkingInitiateResponse {
  data: BvLinkingInitiateData
}

const fetchTopBanks = async (
  request: GetTopBanksRequest
): Promise<IFetchTopBanksResp> => {
  const FFTreatments = useSplitStore.getState().featureFlags
  const FFTreatment = FFTreatments[SPLIT_FEATURE_FLAG.WEB.AKOYA_SUPPORTED]
  const isAkoyaSupported = FFTreatment?.treatment === 'on'

  const response = await httpsClient('/bank/institution/top', {
    method: 'POST',
    body: {
      ...request,
      isAkoyaSupported,
    },
    errorTitle: '[fetchTopBanks]',
  })

  return response.data
}

const findBanks = async (
  request: GetSearchBanksRequest
): Promise<IFetchTopBanksResp> => {
  // Having JS SDK allows you to use Split's client-side APIs. Without react context
  const FFTreatments = useSplitStore.getState().featureFlags
  const FFTreatment = FFTreatments[SPLIT_FEATURE_FLAG.WEB.AKOYA_SUPPORTED]
  const isAkoyaSupported = FFTreatment?.treatment === 'on'

  const response = await httpsClient('/bank/institution/search', {
    method: 'POST',
    body: {
      ...request,
      isAkoyaSupported,
      page: 0,
      size: 4,
    },
    errorTitle: '[findBanks]',
  })

  return response.data
}

const initiateLinking = async (
  body: BvLinkingInitiateRequest
): Promise<BvLinkingInitiateResponse> => {
  const response = await httpsClient('/bank/linking/initiate', {
    method: 'POST',
    body,
    errorTitle: '[initiateLinking]',
  })
  return response.data
}

interface IPlaidLinkSuccessBody {
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  publicToken: string
  source: BankSource
}

interface IPlaidLinkSuccessResp {
  data: {
    bankId: string
  }
}

const plaidLinkSuccess = async (
  body: IPlaidLinkSuccessBody
): Promise<IPlaidLinkSuccessResp> => {
  const response = await httpsClient('/bank/linking/plaid/login-success', {
    method: 'POST',
    body,
    errorTitle: '[plaidLinkSuccess]',
  })
  return response.data
}

type PlaidMetaDataViewName =
  | 'CONNECTED'
  | 'CREDENTIAL'
  | 'ERROR'
  | 'EXIT'
  | 'LOADING'
  | 'MFA'
  | 'SELECT_ACCOUNT'
  | 'SELECT_INSTITUTION'
  | 'TRANSITION_VIEW'
  | 'IS_MATCHED_USER'
  | 'IS_MATCHED_USER_UI'
  | 'CONSENT'

export interface PlaidMetadata {
  error_code: string
  error_message: string
  error_type: string
  exit_status: string
  institution_id: string
  institution_name: string
  institution_search_query: string
  link_session_id: string
  mfa_type: string
  request_id: string
  timestamp: string
  view_name: PlaidMetaDataViewName
}

interface IPlaidLinkEventBody {
  eventName: string
  metadata: PlaidMetadata
  relink: boolean
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

const plaidLinkEvent = async (body: IPlaidLinkEventBody) => {
  const response = await httpsClient('/bank/linking/plaid/link-event', {
    method: 'POST',
    body,
    errorTitle: '[plaidLinkEvent]',
  })
  return response.data
}

interface IFetchBankAccountStatusResp {
  data: {
    banks: IBankInfo[]
  }
}

const fetchBankAccountStatus = async (
  source: BankSource,
  bankId?: string
): Promise<IFetchBankAccountStatusResp> => {
  const response = await httpsClient('/bank/info/status', {
    method: 'POST',
    body: {
      source,
      bankId,
    },
    errorTitle: '[fetchBankAccountStatus]',
  })
  return response.data
}

export interface IFetchBankAccountsBody {
  includePendingBank?: boolean
  includeRemovedBank?: boolean
}

export interface IFetchBankAccountsResp {
  data: {
    banks: IBankSummary[]
  }
}

const fetchBankAccounts = async (
  body: IFetchBankAccountsBody = {
    includePendingBank: true,
    includeRemovedBank: true,
  }
): Promise<IFetchBankAccountsResp> => {
  const options = { ...body, includeInsLogoUrl: true }

  const response = await httpsClient('/bank/v2/info/account', {
    method: 'POST',
    body: {
      options,
    },
    errorTitle: '[fetchBankAccounts]',
  })

  return response.data
}
interface IFinicityLinkSuccessBody {
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

interface IFinicityLinkSuccessResp {
  data: {
    bankId: string
  }
}

const finicityLinkSuccess = async (
  body: IFinicityLinkSuccessBody
): Promise<IFinicityLinkSuccessResp> => {
  const response = await httpsClient('/bank/linking/finicity/login-success', {
    method: 'POST',
    body,
    errorTitle: '[finicityLinkSuccess]',
  })
  return response.data
}

interface IFinicityConnectEventBody {
  type: string
  metadata: any
  relink: boolean
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

const finicityConnectEvent = async (body: IFinicityConnectEventBody) => {
  const response = await httpsClient('/bank/linking/finicity/connect-event', {
    method: 'POST',
    body,
    errorTitle: '[finicityConnectEvent]',
  })
  return response.data
}

const deleteBankAccount = async (bankId: string, source: BankSource) => {
  const response = await httpsClient('/bank/linking/remove', {
    method: 'POST',
    body: {
      bankId,
      source,
    },
    errorTitle: '[deleteBankAccount]',
  })
  return response.data
}

interface AkoyaLinkSuccessBody {
  bankId: number
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  source: BankSource
  akoyaAuthorizationCode: string
  akoyaRedirectUrl: string
  isParallelLinking?: boolean
}

const akoyaLinkSuccess = async (body: AkoyaLinkSuccessBody) => {
  const response = await httpsClient('/bank/linking/akoya/login-success', {
    method: 'POST',
    body,
    errorTitle: '[akoyaLinkSuccess]',
  })
  return response.data
}

type AkoyaLinkEvent =
  | 'IN_APP_OPEN'
  | 'IN_APP_CLOSE'
  | 'OAUTH_REDIRECT'
  | 'SUCCESS'
  | 'FAILURE'
  | 'CANCEL'
  | 'EXIT'

interface AkoyaLinkEventMetaData {
  eventName: AkoyaLinkEvent
  errorCode: string
  errorDescription: string
  timestamp: string
  exitStatus: string
  url: string
}

export interface AkoyaLinkEventBody {
  source: BankSource
  type: AkoyaLinkEvent
  relink: boolean
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  mlTimeStamp: Date
  metaData: AkoyaLinkEventMetaData
}

const akoyaLinkEvent = async (body: AkoyaLinkEventBody) => {
  const response = await httpsClient('/bank/linking/akoya/link-event', {
    method: 'POST',
    body,
    errorTitle: '[akoyaLinkEvent]',
  })
  return response.data
}

const bankApi = {
  fetchTopBanks,
  findBanks,
  initiateLinking,
  plaidLinkSuccess,
  plaidLinkEvent,
  fetchBankAccountStatus,
  fetchBankAccounts,
  finicityLinkSuccess,
  finicityConnectEvent,
  akoyaLinkSuccess,
  akoyaLinkEvent,
  deleteBankAccount,
}

export default bankApi
