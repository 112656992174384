import {
  CacheLocation,
  FingerprintJSPro,
  FpjsClient,
} from '@fingerprintjs/fingerprintjs-pro-spa'
import { ENV } from '@root/config'
import { useAuthStore } from '@root/store'
import { DDLogger } from '../logger'

let fpjsClient: FpjsClient

let fingerprintVisitorData: FingerprintJSPro.GetResult | undefined

export interface GetFingerprintRequest {
  clientRequestId: string
  requestOrigin: 'WEB_LOGIN' | 'WEB_SIGNUP'
}
export const initiateFingerprint = async () => {
  try {
    fpjsClient = new FpjsClient({
      loadOptions: {
        endpoint: [
          'https://moneylion.com/dW1MnzwYPUpmTAyh/7l0LqUuLa51pC79p',
          FingerprintJSPro.defaultEndpoint,
        ],
        scriptUrlPattern: [
          'https://moneylion.com/dW1MnzwYPUpmTAyh/bqNmk3vMwI1xs7pt?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
        apiKey: ENV.FINGERPRINT_KEY,
        region: 'us',
      },
      cacheLocation: CacheLocation.LocalStorage,
      cacheTimeInSeconds: 3600, // 1 hour
    })

    await fpjsClient.init()
  } catch (error: unknown) {
    const initiateFingerprintError = error as Error
    DDLogger.error(
      '[ Fingerprint - initiateFingerprint ]',
      initiateFingerprintError.message
    )
  }
}

export const getFingerprint = async (
  customTag: GetFingerprintRequest
): Promise<FingerprintJSPro.GetResult | undefined> => {
  try {
    if (fingerprintVisitorData?.visitorId) {
      return fingerprintVisitorData
    }

    const linkedId = useAuthStore.getState().user?.id

    fingerprintVisitorData =
      (await fpjsClient.getVisitorDataFromCache({
        linkedId,
        tag: {
          ...customTag,
          tenant: 'ML',
        },
      })) ||
      (await fpjsClient.getVisitorData({
        linkedId,
        tag: {
          ...customTag,
          tenant: 'ML',
        },
      }))
    return fingerprintVisitorData
  } catch (error: unknown) {
    const getFingerprintError = error as Error
    DDLogger.error(
      '[ Fingerprint - getFingerprint ]',
      getFingerprintError.message
    )
    return undefined
  }
}

export const clearFingerprint = () => {
  fingerprintVisitorData = undefined
  fpjsClient.clearCache()
}
