const stringUtil = {
  truncate: (s: string, precision = 15): string => {
    if (s.length > precision) {
      return s.slice(0, precision) + '...'
    }
    return s
  },
}

export default stringUtil
