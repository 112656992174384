import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { UAParser } from 'ua-parser-js'
import { ICDateFormat } from '@root/types/CashAdvance'
import stringUtil from './stringUtil'

dayjs.extend(utc)
dayjs.extend(timezone)

export enum DateFormat {
  /**
   * Date format reference
   * https://devhints.io/moment
   */
  SHORT_MONTH_DAY_YEAR_WITH_COMMA = 'MMM DD, YYYY',
  SHORT_MONTH_DAY = 'MMM DD',
  MONTH_YEAR = 'MMMM YYYY',
}

const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

const formatName = (firstName: string, lastName: string): string => {
  const formattedFirstName = capitalizeFirstLetter(firstName)
  const formattedLastName = capitalizeFirstLetter(lastName)

  return `${formattedFirstName} ${formattedLastName}`
}

const formatBalance = (
  balance: number | string,
  minimumFractionDigits: number,
  needNegative?: boolean
): string => {
  const balanceInNumber = Number(balance)
  const formattedBalance = balanceInNumber.toLocaleString('en', {
    minimumFractionDigits,
    maximumFractionDigits: 2,
  })
  if (balanceInNumber < 0) {
    return `-$${formattedBalance.replace('-', '')}`
  }
  if (parseFloat(formattedBalance) === 0 || !needNegative) {
    return `$${formattedBalance}`
  }
  return `-$${formattedBalance}`
}

const formatDate = (date: dayjs.Dayjs, format: string): string => {
  return date.format(format)
}

const formatDayOrdinalNumbers = (day: number): string => {
  if (day > 3 && day < 21) {
    return `${day}th`
  }

  switch (day % 10) {
    case 1:
      return `${day}st`

    case 2:
      return `${day}nd`

    case 3:
      return `${day}rd`

    default:
      return `${day}th`
  }
}

export const formatDisbursementMethodDuration = (duration: number): string => {
  const durationLabels = {
    withMinutes: 'within minutes',
    withinTwoDays: 'within 1-2 business days',
    withinThreeDays: 'within 2-3 business days',
  }

  if (duration === 0) {
    return durationLabels.withMinutes
  } else if (duration < 49) {
    return durationLabels.withinTwoDays
  }

  return durationLabels.withinThreeDays
}

const formatDOB = (dob: string): string => {
  return dayjs(dob, 'YYYY-MM-DD').format('MM-DD-YYYY')
}

const formatEmail = (email: string): string => {
  return email.replace(/\s+$/, '')
}

const formatExpiryDate = (expiryDate: string): string => {
  return `${expiryDate.substring(0, 2)}/${expiryDate.substring(2)}`
}

const formatLast4Digit = (cardNumber: string): string => {
  return cardNumber.slice(-4)
}

const formatOS = (): string | undefined => {
  const { userAgent } = navigator
  const uaResult = new UAParser(userAgent).getResult()

  const { browser } = uaResult
  const browserName = browser.name
  const browserMajorVersion = browser.version && browser.version.split('.')[0]

  const formattedOS = `${browserName} ${browserMajorVersion}`

  return formattedOS || undefined
}

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')
  return `${formattedMinutes}:${formattedSeconds}`
}

const formatWowSignUpDate = (date: Date | string): string => {
  return dayjs(date).format('MMM YYYY')
}

const formatPhoneNumber = (number: string): string => {
  // check if phone number ((XXX) XXX XXXX) is already formatted
  const isFormatted = /^\(\d{3}\) \d{3} \d{4}$/.test(number)

  if (isFormatted) {
    return number
  }

  return (
    '(' +
    number.substring(0, 3) +
    ') ' +
    number.substring(3, 6) +
    '-' +
    number.substring(6, 10)
  )
}

function sanitizePhoneNumber(
  phoneNumber: string | undefined
): string | undefined {
  return phoneNumber?.toString().replace(/\D/g, '')
}

const removeWhiteSpace = (str: string): string => {
  return str.replace(/\D/g, '')
}

const formatSsn = (ssn: string): string => ssn.replace(/\D/g, '')

const getMuxPlaybackId = (url: string): string | null => {
  try {
    new URL(url)
  } catch (error) {
    // Hardcoding the url for now. This will be removed once we add the filter function in the store
    return '501SuVa3N5qbtVnqTWafgQ6W4Z3EP00hZwDwt8iXezrF8'
  }

  const regex = /\/([A-Za-z0-9]+)\.m3u8$/
  const searchParams = new URL(url).searchParams
  const muxUrl = searchParams.get('url')

  if (!muxUrl) {
    throw new Error('Invalid URL')
  }

  const decodedUrl = decodeURIComponent(muxUrl)

  const playbackId = decodedUrl.match(regex)

  return playbackId ? playbackId[1] : null
}

/**
 * Method used to format the dates that are across all IC modules
 * @param date
 * @param timezone - optional field
 * @param format - optional field, will default to 'MMM D, YYYY' format
 * @returns {string}
 */
export function formatInstacashDate(
  date: string,
  timezone?: string | null,
  format?: ICDateFormat
): string {
  const dateFormat = format ?? ICDateFormat.ABBR_MONTH_DAY_YEAR

  if (timezone) {
    return dayjs(date).tz(timezone).format(dateFormat)
  }
  return dayjs(date).format(dateFormat)
}

/**
 * Method used to format the bank name that are across all IC modules
 * @param bankName - optional field
 * @param lastFourDigit
 * @param methodName
 * @returns {string}
 */
export const formatInstacashBankName = ({
  bankName,
  lastFourDigit,
  methodName,
  truncateSize = 12,
}: {
  bankName?: string
  lastFourDigit: string
  methodName?: string
  truncateSize?: number
}): string => {
  if (!bankName || !lastFourDigit) {
    return methodName || ''
  }

  return `${stringUtil.truncate(bankName, truncateSize)} • ${lastFourDigit}`
}

export const truncateBankMethodName = (
  methodName: string | undefined
): string => {
  if (!methodName) {
    return ''
  }

  const [bankNameOrUserName, lastFourDigit] = methodName.split(' • ')
  return `${stringUtil.truncate(bankNameOrUserName, 12)} • ${lastFourDigit}`
}

const formats = {
  balance: formatBalance,
  date: formatDate,
  dayOrdinalNumbers: formatDayOrdinalNumbers,
  disbursementMethodDuration: formatDisbursementMethodDuration,
  dob: formatDOB,
  email: formatEmail,
  expiryDate: formatExpiryDate,
  last4Digit: formatLast4Digit,
  name: formatName,
  OS: formatOS,
  time: formatTime,
  string: {
    capitalizeFirstLetter,
  },
  wowSignUpDate: formatWowSignUpDate,
  phoneNumber: formatPhoneNumber,
  removeWhiteSpace,
  ssn: formatSsn,
  muxPlaybackId: getMuxPlaybackId,
  instacashDate: formatInstacashDate,
  instacashBankName: formatInstacashBankName,
  truncateBankMethodName: truncateBankMethodName,
  sanitizePhoneNumber,
}

export { formats }
