import { datadogLogs, StatusType } from '@datadog/browser-logs'
import { ENV } from '@root/config'

enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warn',
  ERROR = 'error',
}

/**
 * Datadog Logger
 * Usage:
 * import { Logger } from '@root/utils'
 *
 * Logger.info('info message')
 *
 * Log with context:
 * Logger.error('error message', error)
 */
const DDLogger = {
  // init datadog logger
  init: () => {
    // Init DD browser logs
    datadogLogs.init({
      clientToken: ENV.DATADOG.clientToken,
      site: ENV.DATADOG.site,
      useSecureSessionCookie: true,
      usePartitionedCrossSiteSessionCookie: true,
      trackSessionAcrossSubdomains: true,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: ENV.DATADOG.env,
      forwardConsoleLogs: ['log', 'info'],
    })
  },

  /**
   * Log a message with the given level.
   * @param message string [required] - message to log
   * @param ctx any - optional [error, object, etc]
   * @param level LogLevel - optional
   *
   * In general, we log any kind of error to browser console and send to datadog
   */
  log: (message: string, ctx?: any, level?: LogLevel) => {
    switch (level) {
      case LogLevel.ERROR:
        console.error(message, ctx)
        break

      case LogLevel.WARNING:
        console.warn(message, ctx)
        break

      default:
        console.info(message, ctx)
        break
    }

    // send the browser log to datadog logs
    datadogLogs.onReady(() => {
      datadogLogs.logger.log(message, ctx, StatusType[level || 'info'])
    })
  },

  // info level
  info: (message: string, ctx?: any) => {
    DDLogger.log(message, ctx, LogLevel.INFO)
  },

  // log error to datadog
  error: (message: string, ctx?: any) => {
    DDLogger.log(message, ctx, LogLevel.ERROR)
  },
}

export { DDLogger }
