type ApiErrorParams = {
  name: string
  code: string | number
  title: string
  description: string
}

export class ApiError extends Error {
  readonly code: string | number
  readonly title: string
  readonly description: string

  constructor({ name, code, title, description }: ApiErrorParams) {
    super(name)
    this.name = name
    this.code = code
    this.title = title
    this.description = description
  }
}
