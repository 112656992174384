import React from 'react'
import Snackbar from '@moneylion/mlds-web/Snackbar'
import styled, { css } from 'styled-components'
import { IconsId } from '@moneylion/mlds-web'
import { useUIStore } from '@root/store'

const StyledSnackbar = styled(Snackbar)`
  width: auto;
  cursor: default;
  z-index: 99999999;

  div:first-child {
    align-items: center;
    gap: 14px;
  }

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
      width: calc(100% - 32px);
    }
  `}
`

const NotificationModal: React.FunctionComponent = () => {
  const showNotificationModal = useUIStore(
    (state) => state.showNotificationModal
  )
  const setShowNotificationModal = useUIStore(
    (state) => state.setShowNotificationModal
  )
  const content = useUIStore((state) => state.notificationModalContent)

  if (content) {
    return (
      <StyledSnackbar
        shouldShowSnackbar={showNotificationModal}
        content={content.text}
        icon={content ? (content.icon as IconsId) : undefined}
        autoHideDuration={content.autoHideDuration}
        onHide={() => setShowNotificationModal(false)}
      />
    )
  }
  return null
}

export { NotificationModal }
