import { datadogRum } from '@datadog/browser-rum'
import { Component, ReactNode } from 'react'

interface ErrorBoundaryProps {
  fallbackComponent?: ReactNode
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

/**
 * a wrapper to catch errors in children components and display a fallback UI and also logging the error to datadog and console
 * @param {ReactNode} (optional) fallbackComponent - the fallback to display when an error is caught
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    const renderingError = new Error(error.message)
    renderingError.name = `Rendering Exception`
    renderingError.stack = info.componentStack
    renderingError.cause = error
    datadogRum.addError(renderingError)
  }

  render() {
    if (this.state.hasError && this.props.fallbackComponent) {
      return this.props.fallbackComponent
    }

    return this.props.children
  }
}
