export const SOCIAL_MEDIA_API_PATHS = {
  SAVE_REACTION: (contentId: string) =>
    `/discover/v1/reaction/users/contents/${contentId}`,
}

export const TODAY_TAB_API_PATHS = {
  USER_INPUT: '/content/v1/todaytab/userinput',
  GET_SINGLE_TWEET: (tweetId: string) =>
    `/content/v1/twitter/getSingleTweet/${tweetId}`,
}

export const TODAY_CONTENT_LIST_STALE_TIME = 30

export const TODAY_SEE_MORE_SEE_LESS_EVENTS = {
  TODAY_SEE_MORE_LESS_VIEW: 'today_see_more_less_view',
  TODAY_SEE_MORE_LESS_INTERACT: 'today_see_more_less_interact',
  TODAY_SEE_MORE_LESS_CLOSE: 'today_see_more_less_close',
  TODAY_SEE_LESS_ALL_CARDS: 'today_see_less_all_cards',
}

export const TODAY_SEE_MORE_SEE_LESS_PROPERTY = {
  INTERACTION_SEE_MORE: 'See more',
  INTERACTION_SEE_LESS: 'See less',
}

export const TODAY_HEART_CONTENT_SOURCE = {
  USER_POST: 'USER_POST',
  CONTENTFUL: 'CONTENTFUL',
  NEWS: 'NEWS',
}

export const TODAY_CARD_REACTIONS = {
  HEART: 'HEART',
  UNHEART: 'UNHEART',
}

export enum VIDEO_ACTION_TYPES {
  MUTE = 'mute',
  UNMUTE = 'unmute',
  EXPAND = 'expand',
  SHARE = 'share',
}

export const TODAY_ENGAGEMENT_ACTION_TYPE = {
  HEADER_CTA: 'header_cta',
  FOOTER_CTA: 'footer_cta',
  FULLSCREEN_VIDEO: 'fullscreen_video',
  COMMENT: 'comment',
}
