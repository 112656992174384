import dayjs from 'dayjs'
import jwt_decode from 'jwt-decode'
import { Token } from '@root/types'

const validateToken = (token: string): boolean => {
  try {
    const parsed: Token = jwt_decode(token)

    const expiry = parsed.exp * 1000
    const difference = dayjs(expiry).valueOf() - dayjs().valueOf()

    return difference > 15000
  } catch {
    return false
  }
}

export { validateToken }
