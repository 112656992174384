import { ENV } from '@root/config'

const getCommonAuthRedirectUri = (): string => {
  if (typeof window === 'undefined') return ''
  const { origin } = window.location
  const redirectUri = `${origin}/auth/callback`
  return redirectUri
}

const getCommonAuthCookieDomain = (): string => {
  if (typeof window === 'undefined') return ''
  const { hostname } = window.location
  // support local host
  if (hostname === 'localhost') {
    return 'localhost'
  }
  // support preview links
  if (hostname.includes('pages.dev')) {
    return hostname
  }
  return ENV.COMMON_AUTH_COOKIE_DOMAIN
}

export { getCommonAuthRedirectUri, getCommonAuthCookieDomain }
