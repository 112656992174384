import { useMemo } from 'react'
import { useWindowSize } from '@root/hooks'

export type Viewport =
  | 'mobile'
  | 'tablet-portrait-small'
  | 'tablet-portrait'
  | 'tablet-portrait-large'
  | 'tablet'
  | 'tablet-large'
  | 'tablet-small'
  | 'desktop-small'
  | 'desktop'

// TODO: Web core team to add more breakpoints to MLDS
const useViewport = (): {
  viewport: Viewport
  width: number
} => {
  const { width } = useWindowSize()

  const viewport = useMemo(() => {
    if (width <= 480) return 'mobile'
    if (width <= 700) return 'tablet-portrait-small'
    if (width <= 768) return 'tablet-portrait'
    if (width <= 850) return 'tablet-portrait-large'
    if (width <= 1024) return 'tablet'
    if (width <= 1120) return 'tablet-small'
    if (width <= 1250) return 'tablet-large'
    if (width <= 1366) return 'desktop-small'
    return 'desktop'
  }, [width])

  return {
    viewport,
    width,
  }
}

export { useViewport }
