import { https } from '@root/utils'
import type {
  IGetTipRequest,
  IGetDisbursementMethodRequest,
  IPostApplyInstacashDisbursementRequest,
  IGetV2TipsResponse,
  IPostApplyInstacashDisbursementResponse,
  IGetDisbursementAmountsResponse,
  IGetDisbursementMethodResponse,
  ICreateAppAndLinkEsignResponse,
} from '@ic-request/types'

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Get a list of disbursement amounts
 * @returns {IDisbursementAmountsData}: Returns a list of disbursement amounts
 */
const getDisbursementAmounts =
  async (): Promise<IGetDisbursementAmountsResponse> => {
    const response = await (
      await https({
        errorTitle: '[getDisbursementAmounts]',
      })
    ).get(`/cash-advance/disbursement/amounts`)
    return response.data
  }

/**
 * ! IMPORTANT: This endpoint will fail with error code 400, if the applicationId is undefined
 * @usage: For the Instacash Disbursement Flow
 * @description: Get a list of disbursement methods
 * @param {request}: IGetDisbursementMethodRequest
 * @returns {IDisbursementMethodData}: Returns a list of disbursement methods
 */
const getDisbursementMethod = async (
  request: IGetDisbursementMethodRequest
): Promise<IGetDisbursementMethodResponse> => {
  const { amount, applicationSource, applicationId } = request

  const response = await (
    await https({
      errorTitle: '[getDisbursementMethod]',
    })
  ).get(
    `/cash-advance/disbursement/v3/disbursement-method?applicationAmount=${amount}&applicationSource=${applicationSource}${
      applicationId ? `&applicationId=${applicationId}` : ''
    }`
  )

  return response.data
}

const getV2Tips = async (
  request: IGetTipRequest
): Promise<IGetV2TipsResponse> => {
  const response = await (
    await https({ errorTitle: '[getV2Tips]' })
  ).get(
    `/cash-advance/v2/tips?cashAdvanceAmount=${request.cashAdvanceAmount}&tipSource=${request.tipSource}`
  )
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Apply for Instacash disbursement
 * @returns {IApplyInstacashDisbursementData}: Returns the disbursement application data
 */
const postApplyInstacashDisbursement = async (
  request: IPostApplyInstacashDisbursementRequest
): Promise<IPostApplyInstacashDisbursementResponse> => {
  const response = await (
    await https({
      expectErrorData: true,
      errorTitle: '[postApplyInstacashDisbursement]',
    })
  ).post(`/cash-advance/v5/apply`, request)
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Create Instacash application and link the esign documents
 * @returns {ICreateAppAndLinkEsignData}: Returns the application id and esign documents
 */
const postCreateAppAndLinkEsign = async (
  signal?: AbortSignal
): Promise<ICreateAppAndLinkEsignResponse> => {
  const response = await (
    await https({ signal, errorTitle: '[postCreateAppAndLinkEsign]' })
  ).post('/cash-advance/createAppEsign')
  return response.data
}

export const instacashRequestApi = {
  getDisbursementAmounts,
  getDisbursementMethod,
  getV2Tips,
  postApplyInstacashDisbursement,
  postCreateAppAndLinkEsign,
}
