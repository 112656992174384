const createBlobPdfUrl = (data: ArrayBuffer): string => {
  const blob = new Blob([new Uint8Array(data)], {
    type: 'application/pdf',
  })

  const pdfLink = URL.createObjectURL(blob)

  return pdfLink
}

export { createBlobPdfUrl }
