import { Body, Heading } from '@moneylion/mlds-web'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { UNPROTECTED_PAGES_PATHS } from '@root/constants'
import { ContentContainer, ErrorContainer, ReturnButton } from './index.styles'
import { ERROR_LABELS_404, ERROR_LABELS_500 } from './labels'

interface ErrorPageProps {
  type: '404' | '500'
}

const ErrorPage = (props: ErrorPageProps) => {
  const router = useRouter()
  const ERROR_LABELS =
    props.type === '404' ? ERROR_LABELS_404 : ERROR_LABELS_500
  return (
    <ErrorContainer>
      <ContentContainer>
        <Image
          src={ERROR_LABELS.imageSrc}
          alt="Error Robot"
          width={120}
          height={200}
        />
        <Heading className="text-center">{ERROR_LABELS.title}</Heading>
        <Body className="mt-2 text-center" color="secondary">
          {ERROR_LABELS.description}
        </Body>
        <ReturnButton
          className="mt-4"
          onClick={() => {
            router.push(UNPROTECTED_PAGES_PATHS.root)
          }}
        >
          {ERROR_LABELS.returnButton}
        </ReturnButton>
      </ContentContainer>
    </ErrorContainer>
  )
}

export default ErrorPage
