import { SplitFactory } from '@splitsoftware/splitio'
import { ENV } from '@root/config'
import { SPLIT_FEATURE_FLAG } from '@root/constants'
import { useSplitStore } from '@root/store'
import {
  FeatureFlagConfigLabels,
  SplitFeatureFlagValueType,
} from '@root/types/Featureflag'

export default class Split {
  private static client: SplitIO.IClient | undefined
  private static instance: Split
  private static defaultAttributes: SplitIO.Attributes = {
    source: 'webApp',
  }

  private constructor(email?: string | undefined) {
    if (email) this.connectClient(email)
  }

  public static getSplitInstance = (email?: string | undefined): Split => {
    // if split instance exist use that
    if (!Split.instance && email) {
      Split.instance = new Split(email)
    }
    return Split.instance || new Split()
  }

  /**
   * Returns treatment config for a feature flag based on any passed custom attributes along with default attributes.
   * If the feature flag is not found or split client is not ready, it returns null.
   * @see https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#attribute-syntax
   * @param {SplitFeatureFlagValueType} featureFlagName - Feature flag name
   * @param {SplitIO.Attributes} customAttributes  - Custom attributes to be passed to the split client
   * @returns {SplitIO.TreatmentWithConfig | null} - Returns the treatment value for the feature flag
   */
  public static getSplitFeatureFlagWithAttributes = (
    featureFlagName: SplitFeatureFlagValueType,
    customAttributes: SplitIO.Attributes
  ): SplitIO.TreatmentWithConfig | null => {
    if (Split.client) {
      const featureflagValue: SplitIO.TreatmentWithConfig =
        Split.client.getTreatmentWithConfig(featureFlagName, {
          ...customAttributes,
          ...Split.defaultAttributes,
        })
      return featureflagValue
    }
    return null
  }

  private connectClient = (key: string | undefined): void => {
    if (key && !Split.client) {
      const factory = SplitFactory({
        core: {
          authorizationKey: ENV.SPLIT_IO_KEY_ONBOARDING,
          key,
        },
      })

      Split.client = factory.client()
      // This is when the client is initialising and fetching the split config
      useSplitStore.setState({
        isGettingReady: true,
        splitFetchStatus: 'Fetching',
      })
      Split.client.on(Split.client.Event.SDK_READY, this.onReady)
      Split.client.on(Split.client.Event.SDK_UPDATE, this.onUpdate)
      Split.client.on(Split.client.Event.SDK_READY_TIMED_OUT, () => {
        useSplitStore.setState({
          isGettingReady: false,
          splitFetchStatus: 'Error',
        })
      })
    }
  }

  private onReady = (): void => {
    useSplitStore.setState({
      isReady: true,
      isGettingReady: false,
      splitFetchStatus: 'Success',
    })
    this.onUpdate()
  }

  private onUpdate = (): void => {
    if (Split.client) {
      const ffTreatments = this.flattenConfigObject(SPLIT_FEATURE_FLAG)
      const ffValues = Split.client.getTreatmentsWithConfig(
        ffTreatments,
        Split.defaultAttributes
      )
      useSplitStore.setState({ featureFlags: ffValues })
    }
  }
  /**
   *
   * @param config: type FeatureFlagConfigLabels that are Record<string, Record< string, string >>
   * @returns feature flag values as an array of strings
   *
   * const config: FeatureFlagConfigLabels = {
   *    ONBOARDING: {
   *      shouldIpAddressIncluded: 'ob_web_ic_apply_ip_enabled',
   *    },
   *    MPL: {
   *      shouldShowNewUI: 'mpl_web_show_new_ui',
   *   },
   * };
   * const flattened = flattenConfigObject(config);
   * console.log(flattened); // Output: ['ob_web_ic_apply_ip_enabled', 'mpl_web_show_new_ui']
   */
  private flattenConfigObject(config: FeatureFlagConfigLabels): string[] {
    return Object.values(config).reduce((acc: string[], obj) => {
      acc.push(...Object.values(obj))
      return acc
    }, [])
  }
}
