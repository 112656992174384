/**
 * Filter object to remove undefined values and empty strings of the object values
 * @param object
 * @returns {object}
 */
export const filterObject = (
  object: Record<string, unknown>
): Record<string, unknown> => {
  return Object.fromEntries(
    Object.entries(object).filter(
      ([_, v]) => v !== null && v !== undefined && v !== ''
    )
  )
}

/**
 * Generate query string from params object, remove `undefined` or `null` values
 * @param object
 * @returns {string}
 * @example
 * generateQueryString({
 *  name: 'John',
 *  middleName: '',
 *  lastName: 'Doe',
 *  age: undefined,
 *  job: null,
 * })
 * |=> '?name=John&lastName=Doe'
 */
export const generateQueryString = (
  params: Record<string, unknown>
): string => {
  /**
   * remove `undefined`, `null` and empty string values
   * I mean who wants to search for `undefined`, `null` and empty values, right?
   * ...right?
   */
  const filteredParams = filterObject(params)

  // use new URLSearchParams() to generate query string
  const qs = new URLSearchParams(filteredParams as Record<string, string>)

  // return query string
  return qs.toString()
}
